import { Grid, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MuiDialog } from '../../../Components/Dialog';
import { IContactAdd } from '../types';

const DEFAULT_HOURS = `Mo 08:00 - 16:00
Di 08:00 - 16:00
Mi 08:00 - 16:00
Do 08:00 - 16:00
Fr 08:00 - 16:00
Sa abgeschlossen
So abgeschlossen`;

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleAccept: (data: IContactAdd) => void;
}

export const FirstHelpAddContact: React.FC<Props> = ({isOpen, handleClose, handleAccept}: Props) => {
  const {t} = useTranslation();

  const [dialogObject, setDialogObject] = useState<IContactAdd>({
    landName: '',
    address: '',
    phoneNumber: '',
    openingHours: DEFAULT_HOURS,
    isActive: true
  });

  const _cleanup = () => {
    setDialogObject({
      landName: '',
      address: '',
      phoneNumber: '',
      openingHours: DEFAULT_HOURS,
      isActive: true
    });
    setLandName('');
    setAddress('');
    setPhoneNumber('');
    setOpeningHours(DEFAULT_HOURS);
  };

  const _handleClose = () => {
    if (handleClose) {
      handleClose();
    }
  };

  const _finishModal = () => {
    if (handleAccept && dialogObject) {
      handleAccept(dialogObject);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      _cleanup();
    }
  }, [isOpen]);

  // properties
  const [landName, setLandName] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [openingHours, setOpeningHours] = useState<string>(DEFAULT_HOURS);

  const handleLandNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.value.length === 0) {
      setLandNameError(t('firsthelp.contact.add.error.empty'));
    } else {
      setLandNameError('');
    }
    setLandName(ev.target.value);
  };

  const [landNameError, setLandNameError] = useState<string>('');

  const handleAddressChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.value.length === 0) {
      setAddressError(t('firsthelp.contact.add.error.empty'));
    } else {
      setAddressError('');
    }
    setAddress(ev.target.value);
  };

  const [addressError, setAddressError] = useState<string>('');

  const handlePhoneNumberChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.value.length === 0) {
      setPhoneNumberError(t('firsthelp.contact.add.error.empty'));
    } else if (isNaN(Number(ev.target.value))) {
      setPhoneNumberError(t('firsthelp.contact.add.error.nan'));
    } else if (Number(ev.target.value) < 0) {
      setPhoneNumberError(t('firsthelp.contact.add.error.lessthan0'));
    } else {
      setPhoneNumberError('');
    }
    if (!isNaN(Number(ev.target.value)) && Number(ev.target.value) >= 0) {
      setPhoneNumber(ev.target.value);
    }
  };

  const [phoneNumberError, setPhoneNumberError] = useState<string>('');

  const isHour = (hour: string) => {
    const hourParts = hour.split(':');
    if (hourParts.length !== 2) {
      return false;
    }
    const hh = hourParts[0];
    const mm = hourParts[1];
    if (hh.length !== 2 || mm.length !== 2) {
      return false;
    }
    if (isNaN(Number(hh)) || isNaN(Number(mm))) {
      return false;
    }
    if (Number(hh) < 0 || Number(mm) < 0 || Number(hh) > 23 || Number(mm) > 59) {
      return false;
    }
    return true;
  };

  const matchOpeningHoursSchema = (oh: string) => {
    const DAYS = [ 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So' ];
    const CLOSED = 'abgeschlossen';
    const days = oh.split('\n');
    if (days.length < 7 || days.length > 7) {
      return false;
    }
    const notMatchingDays = days.find((value, index) => !value.startsWith(DAYS[index]));
    if (notMatchingDays) {
      return false;
    }
    for (const day of days) {
      const elements = day.split(' ');
      if (elements.length === 2) {
        if (elements[1] !== CLOSED) {
          return false;
        }
      } else if (elements.length === 4) {
        const openingHour = elements[1];
        const dilimiter = elements[2];
        const closingHour = elements[3];

        if (dilimiter !== '-') {
          return false;
        }
        if (!isHour(openingHour) || !isHour(closingHour)) {
          return false;
        }
      } else {
        return false;
      }
    }
    return true;
  };

  const handleOpeningHoursChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.value.length === 0) {
      setOpeningHoursError(t('firsthelp.contact.add.error.empty'));
    } else if (matchOpeningHoursSchema(ev.target.value)) {
      setOpeningHoursError(t('firsthelp.contact.add.error.daysFormat'));
    } else {
      setOpeningHoursError('');
    }
    setOpeningHours(ev.target.value);
  };

  const [openingHoursError, setOpeningHoursError] = useState<string>('');

  const updateObjectData = () => {
    setDialogObject({
      landName,
      address,
      phoneNumber,
      openingHours,
      isActive: true
    });
  };

  return (
    <MuiDialog width={500} open={isOpen} onClose={_handleClose} title={t('firsthelp.contact.add.label')} buttons={[
      {
        label: t('knowledge.add.dialog.cancel'),
        action: _handleClose
      },
      {
        label: t('knowledge.add.dialog.save'),
        action: _finishModal,
        disabled: !dialogObject
      }
    ]}>
      <Grid container>
        <Grid item xs={12}>
          <TextField 
            id="standard-basic" 
            style={{width: '100%'}} 
            label={t('firsthelp.contact.add.landName')} 
            variant="standard" 
            value={landName} 
            onChange={handleLandNameChange} 
            onBlur={updateObjectData}
            error={!!landNameError}
            helperText={
              landNameError && (<div  style={{color: '#f44336'}} >{landNameError}<br/></div>)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField 
            id="standard-basic" 
            style={{width: '100%'}} 
            label={t('firsthelp.contact.add.address')} 
            variant="standard" 
            multiline
            minRows={1}
            maxRows={7}
            value={address} 
            onChange={handleAddressChange}  
            onBlur={updateObjectData}
            error={!!addressError}
            helperText={
              addressError && (<div  style={{color: '#f44336'}} >{addressError}<br/></div>)
            }
          />
        </Grid>
        
        <Grid item xs={12}>
          <TextField 
            id="standard-basic" 
            style={{width: '100%'}} 
            label={t('firsthelp.contact.add.phone')} 
            variant="standard" 
            value={phoneNumber} 
            onChange={handlePhoneNumberChange}  
            onBlur={updateObjectData}
            error={!!phoneNumberError}
            helperText={
              phoneNumberError && (<div  style={{color: '#f44336'}} >{phoneNumberError}<br/></div>)
            }
          />
        </Grid>

        <Grid item xs={12}>
          <TextField 
            id="standard-basic" 
            style={{width: '100%'}} 
            label={t('firsthelp.contact.add.openingHours')} 
            variant="standard" 
            multiline
            minRows={7}
            maxRows={7}
            value={openingHours} 
            onChange={handleOpeningHoursChange}  
            onBlur={updateObjectData}
            error={!!openingHoursError}
            helperText={
              openingHoursError && (<div  style={{color: '#f44336'}} >{openingHoursError}<br/></div>)
            }
          />
        </Grid>
      </Grid>
    </MuiDialog>
  );
};