import jwtDecode from 'jwt-decode';
import dayjs from 'dayjs';
import { TokenValidator } from './TokenValidator';

interface DecodedToken {
  exp: number;
  iat: number;
}

const invalidToken: DecodedToken = {
  exp: 0,
  iat: 0
};

const decodeToken = (token: string): DecodedToken => {
  try {
    return jwtDecode(token);
  } catch {
    return invalidToken;
  }
};

const getExpirationTime = (token: string): Date => {
  const jwtToken = decodeToken(token);
  const expiredTime = dayjs(jwtToken.exp * 1000);
  return expiredTime.isValid() ? expiredTime.toDate() : new Date();
};

export const JwtTokenValidator: TokenValidator = {
  isValid: (token: string) => {
    const expiredTime = getExpirationTime(token);
    return dayjs().isBefore(expiredTime);
  }
};
