import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HeadCell, TableProvider } from '../../Components/TableProvider';
import { RowCell } from '../../Components/TableRowProvider';
import { PageLayout } from '../../Themes/PageLayout';
import { deleteQuestion, getCategories, getQuestions } from './Api';
import { QuizQuestionData, ICategoriesResponse } from './types';
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TableCell, Tooltip } from '@material-ui/core';
import { TFunction } from 'i18next';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import { green, red } from '@material-ui/core/colors';
import { ConfirmDialog } from './commons/ConfirmDialog';
import { v4 as uuidv4 } from 'uuid';
import { Separator } from '../../Components/Separator';
import { useHistory } from 'react-router-dom';

export const QuizPage: React.FC = () => {
  const enabledCellGenerator = (field: string) => (_cellMeta: RowCell<QuizQuestionData>, rData: QuizQuestionData, _index?: number, key?: string) => isEnabled(rData[field as keyof QuizQuestionData], t, key);
  const isEnabled = (isEnabled: boolean, t: TFunction, key?: string): JSX.Element => {
    let icon;
    if (isEnabled) {
      icon = (<CheckCircleIcon style={{ right: 0, color: green[500] }}/>);
    } else {
      icon = (<HighlightOffIcon style={{ right: 0, color: red[500] }}/>);
    }
    return (
      <TableCell align="right" key={key} style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '200px'
      }}>
        <Tooltip title={t(`category.${isEnabled ? 'enabled' : 'disabled'}`) as string}>
          {icon}
        </Tooltip>
      </TableCell>);
  }; 
  
  const generateActionCell = (_cellMeta: RowCell<QuizQuestionData>, rowData: QuizQuestionData, _index?: number, key?: string) => {
    return (
      <TableCell align="right" key={key}>                
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <IconButton color="primary" aria-label="info" onClick={editQuestionBtn(rowData.id)}>
              <EditIcon/>
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <IconButton color="primary" aria-label="info" onClick={deleteQuestionBtn(rowData.id)}>
              <DeleteIcon/>
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
    );
  };
  const { t } = useTranslation();
  
  const headCells: HeadCell<QuizQuestionData>[] = [
    { id: 'id', align: 'center', numeric: false, width: 40, disablePadding: true, label: t('table.id') },
    { id: 'questionText', numeric: false, disablePadding: false, label: t('table.name') },
    { id: 'isActive', numeric: true, disablePadding: false, label: t('table.active') },
    { id: 'actions', align: 'left', numeric: true, width: 100, disablePadding: false, label: t('table.actions') },
  ];
  
  const rowsCells: RowCell<QuizQuestionData>[] = [
    { id: 'id', align: 'left', style: {maxWidth: '40px'}, disablePadding: true},
    { id: 'questionText', align: 'left', hasTooltip: true, maxLength: 29},
    { id: 'isActive', align: 'right', cellGenerator: enabledCellGenerator('isActive')},
    { id: 'actions', cellGenerator: generateActionCell}
  ];

  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [questions, setQuestions] = useState<QuizQuestionData[]>();
  const [categories, setCategories] = useState<ICategoriesResponse[]>();
  const [categoryId, setCategoryId] = useState<number>();
  const [questionsCount, setQuestionsCount] = useState<number>();
  const [page, setPage] = useState<number>(0);
  const rowsPerPage = 10;
  
  const [confirmDialog, setConfirmDialog] = useState<JSX.Element | undefined>();

  const refreshCategoryData = async () => {
    setLoading(true);
    const cats = await getCategories({page: 0, rowsPerPage: 999});
    if (cats.body && cats.body.data) {
      setCategories(cats.body.data);
    }
    setLoading(false);
  };

  const refreshQuestionsData = async () => {
    setLoading(true);
    const qs = await getQuestions({page: page, rowsPerPage: rowsPerPage}, categoryId || 1);
    if (qs.body && qs.body.data) {
      setQuestions(qs.body.data);
      setQuestionsCount(qs.body.dataCount || 0);
    }
    setLoading(false);
  };


  const deleteQuestionBtn = (rowId: number) => async () => {
    setLoading(true);
    const handleDelete = async () => {
      setLoading(true);
      if (questions) {
        await deleteQuestion(rowId);
        await refreshQuestionsData();
        setConfirmDialog(undefined);
      }
      setLoading(false);
    };

    const handleDeclineDelete = () => {
      setConfirmDialog(undefined);
    };
    setConfirmDialog(
      <ConfirmDialog isOpen={true} text={t('confirm.deleteQuizQuestion.text')} subText={t('confirm.deleteQuizQuestion.subText', {id: rowId})}  onAccept={handleDelete} onCancel={handleDeclineDelete}/>
    );
    setLoading(false);
  };

  const addQuestionBtn = () => {
    if (categoryId !== undefined) {
      history.push(`/backoffice/quizes/add/${categoryId}`);
    }
  };


  const editQuestionBtn = (qId: number) => () => {
    if (categoryId !== undefined) {
      history.push(`/backoffice/quizes/edit/${categoryId}/${qId}`);
    }
  };

  const handleCategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCategoryId(event.target.value as number);
  };

  useEffect(() => {
    refreshCategoryData();
  }, []);

  useEffect(() => {
    if (categoryId !== undefined) {
      refreshQuestionsData();
    }
  }, [page]);

  useEffect(() => {
    if (categoryId !== undefined) {
      setPage(0);
      refreshQuestionsData();
    }
  }, [categoryId]);

  return (
    <PageLayout lock={isLoading} aligment="left" title={t('quiz.menuLabel')} buttons={[{icon: AddBoxIcon, action: addQuestionBtn, disabled: categoryId === undefined}]}>
      <div>
        {confirmDialog}
        <FormControl fullWidth style={{maxWidth: '300px'}}>
          <InputLabel id="category-selector-label">{t('categories.menuLabel')}</InputLabel>
          <Select
            labelId="category-selector-label"
            id="category-selector"
            value={categoryId}
            fullWidth
            onChange={handleCategoryChange}
          >
            {categories?.map((category) => {
              return (<MenuItem key={`category_menu_item_${uuidv4()}`} value={category.id}>{category.categoryName}</MenuItem>);
            })}
          </Select>
        </FormControl>
        <Separator/>
        <TableProvider<QuizQuestionData> 
          headCells={headCells}
          rowCells={rowsCells}
          tableData={questions || []}
          selectedRows={[]}
          tableDataCount={questionsCount || 0}
          paginated={true}
          paginationRange={[10]}
          rowsPerPage={rowsPerPage}
          pageChange={(newPage) => {
            setPage(newPage);
          }}
          page={page}
          rowsPerPageChange={() => {/** */}}
        />
      </div>
    </PageLayout>
  );
};
