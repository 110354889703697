import {
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from '../../Themes/PageLayout';
import { getCards } from './Api';
import { ICard } from './types';
import QRCode from 'react-qr-code';
import Logo from '../../Themes/Assets/Images/Logo.png';
import BrushPaint from '../../Themes/Assets/Images/brushPaint.png';
import PrintIcon from '@material-ui/icons/Print';
import CropFreeIcon from '@material-ui/icons/CropFree';
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';

const useStyle = makeStyles({
  qrCodeBg: {
    '& path': {
      boxBhadow: '2px 2px 4px #000',
    },
  },
});

export const CardPage: React.FC = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [cards, setCards] = useState<ICard[]>();
  const [offWitdh, setOffWidth] = useState<number>(document.body.offsetWidth);

  const gatherData = async () => {
    setLoading(true);
    const cardsRaw = await getCards();
    if (cardsRaw.body) {
      setCards(cardsRaw.body);
    }
    setLoading(false);
  };

  const instructionPoints = [
    t('cards.instruction.point1'),
    t('cards.instruction.point2'),
    t('cards.instruction.point3'),
    t('cards.instruction.point4'),
  ];

  const resizeHandler = () => {
    setOffWidth(document.body.offsetWidth);
  };

  useEffect(() => {
    gatherData();
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const handlePrint = (index: number, area: 'whole' | 'code') => async () => {
    const element = document.getElementById(`registration-card-${index}`);
    if (element && area === 'whole') {
      const png = await htmlToImage.toPng(element);
      download(png, `registrationCode${index}.png`);
    } else if (element && area === 'code') {
      const code = element.querySelector('svg');
      if (code && code.parentElement) {
        code.parentElement.style.background = 'white';
        code.parentElement.style.padding = '20px';
        Array.from(document.querySelectorAll('path[fill="#0e627d"]')).forEach(
          (el) => el.setAttribute('fill', 'black')
        );
        Array.from(
          document.querySelectorAll('path[fill="transparent"]')
        ).forEach((el) => el.setAttribute('fill', 'white'));
        const codePng = await htmlToImage.toPng(code.parentElement);
        download(codePng, `code${index}.png`);
        Array.from(document.querySelectorAll('path[fill="black"]')).forEach(
          (el) => el.setAttribute('fill', '#0e627d')
        );
        Array.from(document.querySelectorAll('path[fill="white"]')).forEach(
          (el) => el.setAttribute('fill', 'transparent')
        );
        code.parentElement.style.background = 'transparent';
        code.parentElement.style.padding = '0px';
      }
    }
  };

  // This component needs to use styles instead of className because styles are dynamicly calculated
  const generateCardElement = (card: ICard, index: number) => {
    return (
      <Grid item xs={12} style={{ position: 'relative' }}>
        <Typography component="h5" variant="h6">
          {t(`cards.type.${card.type.toLowerCase()}`)}:
        </Typography>
        <br />
        <Paper
          id={`registration-card-${index}`}
          elevation={3}
          style={{
            overflow: 'hidden',
            border: '#F8EA1D 2px solid',
            width: '100%',
            height: 'calc(100vw / 4)',
          }}
        >
          <Grid container spacing={1} style={{ margin: '0px' }}>
            <Grid
              item
              xs={3}
              style={{
                padding: 0,
                position: 'relative',
                borderRight: '#F8EA1D 2px dashed',
              }}
            >
              <img
                src={Logo}
                style={{
                  width: '50%',
                  marginLeft: '25%',
                  marginRight: '25%',
                  paddingTop: `${(10 * offWitdh) / 1920}px`,
                }}
              />
              <div
                style={{
                  padding: `${(20 * offWitdh) / 1920}px`,
                  fontSize: `${(13 * offWitdh) / 1920}px`,
                }}
              >
                <span>{t('cards.instruction.header')}</span>
                <ul
                  style={{
                    margin: 0,
                    paddingLeft: `${(30 * offWitdh) / 1920}px`,
                  }}
                >
                  {instructionPoints.map((instr, index) => (
                    <li key={`instr-key-${index}`}>{instr}</li>
                  ))}
                </ul>
              </div>
            </Grid>
            <Grid
              item
              xs={9}
              style={{ position: 'relative', height: 'calc(100vw / 4)' }}
            >
              <img
                src={BrushPaint}
                style={{
                  position: 'absolute',
                  top: '-50%',
                  right: 'calc(100vw / 20)',
                  filter: 'blur(2px)',
                  zIndex: 1,
                  width: '80%',
                }}
              />
              <div style={{ height: '10%', zIndex: 2, position: 'relative' }}>
                <span style={{ fontSize: `${(1.25 * offWitdh) / 1920}rem` }}>
                  {t(`cards.type.${card.type.toLowerCase()}`)}
                </span>
              </div>
              <div style={{ height: '70%', zIndex: 2, position: 'relative' }}>
                <div
                  style={{ marginLeft: `calc((100% - ${offWitdh / 6}px) / 2)` }}
                >
                  <div style={{ width: 'max-content' }}>
                    <QRCode
                      className={classes.qrCodeBg}
                      value={`https://startklar-app.de?code=${card.code}`}
                      level="H"
                      size={offWitdh / 6}
                      fgColor={'#0e627d'}
                      bgColor={'transparent'}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: '20%',
                  padding: '5px',
                  textAlign: 'center',
                  zIndex: 2,
                  position: 'relative',
                  color: '#0e627d',
                }}
              >
                <span>
                  {t('cards.instruction.yourCode')}
                  <br />
                  {card.code}
                </span>
              </div>
            </Grid>
          </Grid>
        </Paper>
        <IconButton
          style={{
            position: 'absolute',
            top: '70px',
            right: '20px',
            color: '#DBD032',
            zIndex: 20,
          }}
          onClick={handlePrint(index, 'whole')}
        >
          <PrintIcon />
        </IconButton>
        <IconButton
          style={{
            position: 'absolute',
            top: '70px',
            right: '70px',
            color: '#DBD032',
            zIndex: 20,
          }}
          onClick={handlePrint(index, 'code')}
        >
          <CropFreeIcon />
        </IconButton>
      </Grid>
    );
  };

  return (
    <PageLayout lock={isLoading} aligment="left" title={t('cards.menuLabel')}>
      <div>
        {!cards && !isLoading && t('cards.error')}
        {cards && !isLoading && (
          <Grid container spacing={3}>
            {cards.map((card, index) => {
              return generateCardElement(card, index);
            })}
          </Grid>
        )}
      </div>
    </PageLayout>
  );
};
