import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MuiDialog } from '../../../Components/Dialog';
import { FrameLoading } from '../../../Components/FrameLoading';
import { HeadCell, TableProvider } from '../../../Components/TableProvider';
import { RowCell } from '../../../Components/TableRowProvider';
import { getEventAnswersForQuestion } from '../Api';
import { IAnswerDetailsData, IChartData } from '../types';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

interface DataDialogProps {
  isOpen: boolean;
  questionText: string;
  chartsData?: IChartData[];
  questionId: number;
  eventId: number;
  handleClose: () => void;
}

export const DataDialog: React.FC<DataDialogProps> = ({isOpen, questionText, chartsData, questionId, eventId, handleClose}: DataDialogProps) => {
  const {t} = useTranslation();
  const [lock, setLock] = useState(false);
  const onClose = () => {
    if (handleClose) {
      handleClose();
    }
  };

  const [answers, setAnswers] = useState<IAnswerDetailsData[]>();
  const [answersCount, setAnswersCount] = useState<number>(0);
  const [answersPage, setAnswersPage] = useState<number>(0);
  const answersRowsPerPage = 10;

  const gatherData = async () => {
    setLock(true);
    if (eventId && questionId && isOpen) {
      const data = await getEventAnswersForQuestion(eventId, questionId, {
        page: answersPage,
        rowsPerPage: answersRowsPerPage
      });
      if (data && data.body) {
        setAnswers(data.body.data);
        setAnswersCount(data.body.dataCount);
      }
    }
    setLock(false);
  };

  useEffect(() => {
    gatherData();
  }, [answersPage,isOpen]);

  useEffect(() => {
    if (isOpen) {
      console.log('has chartsData', chartsData);
      setAnswersPage(0);
    }
  }, [isOpen]);

  const headAnswersCells: HeadCell<IAnswerDetailsData>[] = [
    { id: 'id', align: 'center', numeric: false, width: 40, disablePadding: true, label: t('table.id') },
    { id: 'insertionDate', numeric: false, disablePadding: false, label: t('table.answerDate') },
    { id: 'answer', numeric: false, disablePadding: false, label: t('table.answer') },
  ];
  
  const rowsAnswersCells: RowCell<IAnswerDetailsData>[] = [
    { id: 'id', align: 'left', style: {maxWidth: '40px'}, disablePadding: true},
    { id: 'insertionDate', align: 'left', hasTooltip: true, maxLength: 128},
    { id: 'answer', align: 'left', hasTooltip: true, maxLength: 256},
  ];

  const CustomTooltip = ({active,
    payload,
    label}:{ active?: boolean, payload?: {value: string}[], label?: string }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{background: '#0e627d', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '-10px', borderRadius: '4px', boxShadow: '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)'}}>
          <p className="label">{`${label} : ${payload[0].value}`}</p>
        </div>
      );
    }
  
    return null;
  };

  return (
    <MuiDialog width={500} open={isOpen} onClose={onClose} title={t('event.question.data')}>
      <FrameLoading isLoading={lock}>
        <Grid container>
          <Grid item xs={12} style={{paddingTop: '10px', paddingBottom: '10px'}}>
            {questionText}
          </Grid>
          {chartsData &&
            (
              <Grid item xs={12} style={{minHeight: '300px', background: 'white'}}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={chartsData}
                    margin={{
                      top: 0,
                      right: 20,
                      left: -30,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="label"/>
                    <YAxis/>
                    <Tooltip content={<CustomTooltip />} />
                    <Bar dataKey="value" fill="#F8EA1D" />
                  </BarChart>
                </ResponsiveContainer>
              </Grid>
            )
          }
          <Grid item xs={12}>
            <TableProvider<IAnswerDetailsData> 
              headCells={headAnswersCells}
              rowCells={rowsAnswersCells}
              tableData={answers || []}
              selectedRows={[]}
              tableDataCount={answersCount || 0}
              paginated={true}
              paginationRange={[answersRowsPerPage]}
              rowsPerPage={answersRowsPerPage}
              pageChange={(newPage: number) => {
                setAnswersPage(newPage);
              }}
              page={answersPage}
              rowsPerPageChange={() => {/** */}}
            />
          </Grid>
        </Grid>
      </FrameLoading>
    </MuiDialog>
  );
};