import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import InfoIcon from '@material-ui/icons/Info';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';

const IS_TOKEN_BUTTON_ENABLED = process.env.REACT_APP_TOKEN_BUTTON === 'true';
const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingRight: theme.spacing(3)
  },
  menuButton: {
    marginRight: theme.spacing(4)
  },
  title: {
    flexGrow: 1,
    color: '#F8EA1D'
  },
  menuButtonHidden: {
    display: 'none'
  }
}));

interface DashboardToolbarProps {
  onOpen: VoidFunction;
  isOpen: boolean;
}

export const DashboardToolbar: React.FC<DashboardToolbarProps> = ({
  onOpen,
  isOpen
}: DashboardToolbarProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Toolbar className={classes.toolbar}>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={onOpen}
        className={clsx(classes.menuButton, isOpen && classes.menuButtonHidden)}
      >
        <MenuIcon />
      </IconButton>
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        className={classes.title}
      >
        {t('common.appName')}
        {IS_TOKEN_BUTTON_ENABLED && (
          <Tooltip title="Click to copy token">
            <IconButton aria-label="info" onClick={() => {
              const textArea = document.createElement('textarea');
              textArea.value = `Bearer ${localStorage.getItem('startklar-backoffice-panel')}` || '';
              document.body.appendChild(textArea);
              textArea.select();
              document.execCommand('Copy');
              textArea.remove();
            }}>
              <InfoIcon style={{color: 'white'}}/>
            </IconButton>
          </Tooltip>
        )}
      </Typography>
      {/* <UserInfo /> */}
      {/* <LogoutButton /> */}
    </Toolbar>
  );
};
