import { FormControlLabel, Grid, IconButton, Paper, Switch, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { IAnswer, AnswerType, ANSWER_ORDER } from './AnswersList';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';


interface Props {
  answer: IAnswer;
  index: number;
  onDelete: (id: AnswerType) => void;
  onTextChange: (id: AnswerType, value: string) => void;
  onCorrectSwitch: (id: AnswerType) => void;
}

export const Answer: React.FC<Props> = ({index, answer, onDelete, onTextChange, onCorrectSwitch}: Props) => {
  
  const { t } = useTranslation();

  const [letter, setLetter] = useState<AnswerType>(ANSWER_ORDER[index]);
  const [answerText, setAnswerText] = useState<string>(answer.text);
  const [correct, setCorrect] = useState<boolean>(answer.isCorrect);

  const handleAnswerChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setAnswerText(ev.target.value);
  };

  const handleCorrectChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.checked === false) {
      return;
    }
    setCorrect(ev.target.checked);
    onCorrectSwitch(letter);
  };

  const handleDelete = () => {
    if (onDelete) {
      onDelete(letter);
    }
  };

  const onTextBlur = () => {
    onTextChange(letter, answerText);
  };

  useEffect(() => {
    setTimeout(() => {
      const {id, text, isCorrect} = answer;
      if (letter !== id) {
        setLetter(id);
      }
      if (text !== answerText) {
        setAnswerText(text);
      }
      if (correct != isCorrect) {
        setCorrect(isCorrect);
      }
    }, 200);
  }, [answer]);

  return (
    <Paper elevation={2} style={{marginTop: '10px'}}>
      <Grid container spacing={1}>
        <Grid item xs={1} style={{
          borderRight: '1px solid rgb(224, 224, 224)',
          textAlign: 'center'
        }}>
          <Typography variant={'body1'} component={'span'}>
            {letter}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField 
            id="standard-basic" 
            style={{width: '100%'}} 
            label={t('question.answer.text')} 
            variant="standard" 
            value={answerText} 
            onChange={handleAnswerChange} 
            onBlur={onTextBlur}
          />
        </Grid>
        <Grid item xs={1}>
          <FormControlLabel
            style={{
              width: '100%',
              marginRight: '-48px',
              marginLeft: '0px'
            }}
            control={
              <Switch checked={correct} onChange={handleCorrectChange} />
            }
            label={t('question.correct.field')}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton style={{float: 'right'}} color="primary" aria-label="info" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};