import DashboardIcon from '@material-ui/icons/Dashboard';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import AppsIcon from '@material-ui/icons/Apps';
import CameraIcon from '@material-ui/icons/Camera';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ListAltIcon from '@material-ui/icons/ListAlt';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import { RouteDefinition } from './types';
import { DashBoardPage } from '../Screens/Backoffice/DashboardPage';
import { CategoriesPage } from '../Screens/Backoffice/CategoriesPage';
import { CardPage } from '../Screens/Backoffice/CardPage';
import { KnowledgeStock } from '../Screens/Backoffice/KnowledgeStock';
import { QuizPage } from '../Screens/Backoffice/QuizPage';
import { EditPage } from '../Screens/Backoffice/Quiz/EditPage';
import { AddPage } from '../Screens/Backoffice/Quiz/AddPage';
import { EvaluationPage } from '../Screens/Backoffice/EvaluationPage';
import { EventDatePage } from '../Screens/Backoffice/EventDatePage';
import { EditEventDate } from '../Screens/Backoffice/EventDate/EditEventDate';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import { FirstHelpPage } from '../Screens/Backoffice/FirstHelp/FirstHelpPage';
import { EventImpressionsPage } from '../Screens/Backoffice/EventImpressionsPage';
// import { NotFoundPage } from '../Screens/NotFound';

export const availableRoutes: RouteDefinition[] = [
  {
    label: 'dashboard.menuLabel',
    path: '/backoffice/',
    Component: DashBoardPage,
    Icon: DashboardIcon,
  },
  {
    label: 'cards.menuLabel',
    path: '/backoffice/cards/',
    Component: CardPage,
    Icon: CameraIcon
  },
  {
    label: 'categories.menuLabel',
    path: '/backoffice/categories',
    Component: CategoriesPage,
    Icon: AppsIcon,
  },
  {
    label: 'knowledge.menuLabel',
    path: '/backoffice/knowledge',
    Component: KnowledgeStock,
    Icon: MenuBookIcon,
  },
  {
    label: 'quizes.menuLabel',
    path: '/backoffice/quizes',
    Component: QuizPage,
    Icon: LiveHelpIcon,
    subpaths: [
      {
        subpath: '/backoffice/quizes/edit/:catId/:questionId',
        SubComponent: EditPage,
      },
      {
        subpath: '/backoffice/quizes/add/:catId',
        SubComponent: AddPage,
      }
    ]
  },
  {
    label: 'evaluation.tile',
    path: '/backoffice/evaluation',
    Component: EvaluationPage,
    Icon: ListAltIcon,
  },
  {
    label: 'events.menuLabel',
    path: '/backoffice/events',
    Component: EventDatePage,
    Icon: DateRangeIcon,
    subpaths: [
      {
        subpath: '/backoffice/events/:evId',
        SubComponent: EditEventDate
      }
    ]
  },
  {
    label: 'firsthelp.menuLabel',
    path: '/backoffice/firsthelp',
    Component: FirstHelpPage,
    Icon: LocalHospitalIcon,
  },
  {
    label: 'impressions.menuLabel',
    path: '/backoffice/impressions',
    Component: EventImpressionsPage,
    Icon: CloudDoneIcon,
  },
];
