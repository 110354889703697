import { makeStyles, Table, TableBody, TableContainer, TablePagination } from '@material-ui/core';
import React, { useState, useEffect} from 'react';
import { TableHeaderProvider } from './TableHeaderProvider';
import { RowCell, TableRowProvider } from './TableRowProvider';

export interface HeadCell<T> {
  disablePadding: boolean;
  id: keyof T;
  label: string;
  numeric: boolean;
  width?: number;
  sortable?: boolean;
  isCheckbox?: boolean;
  align?: 'left' | 'right' | 'center';
}

export interface ActionsCell {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions?: any;
}

export type TableOrder = 'asc' | 'desc';

interface TableProviderProps<T extends {id: number}> {
  headCells: HeadCell<T>[];
  rowCells: RowCell<T>[],
  tableData: T[];
  selectedRows: number[];
  tableDataCount: number;
  selectedRowsChanged?: (selectedRows: number[], all: boolean) => void;
  rowDecorator?: (row: T, index: number) => React.CSSProperties;
  orderChange?: (order: TableOrder, orderBy: keyof T) => void;
  paginated: boolean;
  paginationRange?: number[];
  page?: number;
  pageChange?: (newPage: number) => void;
  rowsPerPage?: number;
  rowsPerPageChange?: (rppCount: number) => void;
}


const useStyles = makeStyles(() => ({
  table: {
    width: '100%',
  },
}));


export function TableProvider<T extends {id: number}> (props: TableProviderProps<T>): JSX.Element {
  const {
    headCells, rowCells, tableData, selectedRows, 
    tableDataCount, selectedRowsChanged, rowDecorator, 
    paginated, paginationRange, page, rowsPerPage,
    pageChange, rowsPerPageChange, orderChange
  } = props;

  const classes = useStyles();
  const [selected, setSelected] = useState<number[]>(selectedRows.slice());
  const [forceAll, setForceAll] = useState<boolean>(false);
  const [selectedAny, setSelectedAny] = useState<boolean>(!!selectedRows.length);
  const [selectedAll, setSelectedAll] = useState<boolean>(selectedRows.length === tableDataCount || forceAll);


  if (paginated && (!paginationRange || paginationRange.length === 0 || 
        !pageChange || !rowsPerPageChange || page === undefined || rowsPerPage === undefined)) {
    console.log('paginated', paginated);
    console.log('paginationRange', paginationRange);
    console.log('pageChange', pageChange);
    console.log('rowsPerPageChange', rowsPerPageChange);
    console.log('page', page);
    console.log('rowsPerPage', rowsPerPage);
    throw new Error('Pagination set to true, but some properties is missing (paginationRange, defaultPaginationRangeIndex, pageChange, rowsPerPageChange');
  }
  
  const handleChangePage = (event: unknown, newPage: number) => {
    if (pageChange) {
      pageChange(newPage);
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rpp = parseInt(event.target.value, 10);
    if (rowsPerPageChange) {
      rowsPerPageChange(rpp);
    }
    if (pageChange) {
      pageChange(0);
    }
  };

  const checkRow = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const sel = selected.slice();
    if (event.currentTarget.checked && !selected.includes(index)) {
      sel.push(index);
    } else if (!event.currentTarget.checked && selected.includes(index)) {
      sel.splice(selected.indexOf(index), 1);
    }
    setSelected(sel);
  };

  const checkAllRows = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForceAll(event.currentTarget.checked);
  };

  useEffect(() => {
    setSelectedAny(!!selected.length);
    setSelectedAll(selected.length === tableDataCount || forceAll);
    if (selectedRowsChanged) {
      selectedRowsChanged(selected, selectedAll);
    }
  }, [selected, forceAll]);

  
  return (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'small'}
          aria-label="enhanced table">
          <TableHeaderProvider<T> headCells={headCells} selectAll={checkAllRows} selectedAll={selectedAll} selectedAny={selectedAny} orderChange={orderChange}/>
          <TableBody>
            {
              tableData.map((row: T, index: number) => (
                <TableRowProvider<T> 
                  key={index}
                  headCells={headCells} 
                  rowCells={rowCells} 
                  rowData={row} 
                  index={index}
                  selectedRows={selected}
                  itemSelector={checkRow}
                  rowDecorator={rowDecorator ? rowDecorator(row, index) : undefined}
                />
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      {paginated ? 
        (
          <TablePagination
            rowsPerPageOptions={paginationRange}
            component="div"
            count={tableDataCount}
            rowsPerPage={rowsPerPage || 0}
            page={page || 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : undefined}
    </>
  );
}


