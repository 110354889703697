import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';

import { useSession } from '../Contexts/SessionContext';

export const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }: RouteProps) => {
  const { isLogged } = useSession();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLogged() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/backoffice',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
};
