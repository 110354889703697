import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HeadCell, TableProvider } from '../../Components/TableProvider';
import { RowCell } from '../../Components/TableRowProvider';
import { PageLayout } from '../../Themes/PageLayout';
import { addArticle, deleteArticle, editArticle, getArticles, getCategories } from './Api';
import { ArticleData, IArticleMutationObject, ICategoriesResponse } from './types';
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TableCell, Tooltip } from '@material-ui/core';
import { TFunction } from 'i18next';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { green, red } from '@material-ui/core/colors';
import { AddEditDialog } from './KnowledgeStock/AddEditDialog';
import { ConfirmDialog } from './commons/ConfirmDialog';
import { Separator } from '../../Components/Separator';
import { v4 as uuidv4 } from 'uuid';


export const KnowledgeStock: React.FC = () => {
  const enabledCellGenerator = (field: string) => (_cellMeta: RowCell<ArticleData>, rData: ArticleData, _index?: number, key?: string) => isEnabled(rData[field as keyof ArticleData], t, key);
  const isEnabled = (isEnabled: boolean, t: TFunction, key?: string): JSX.Element => {
    let icon;
    if (isEnabled) {
      icon = (<CheckCircleIcon style={{ right: 0, color: green[500] }}/>);
    } else {
      icon = (<HighlightOffIcon style={{ right: 0, color: red[500] }}/>);
    }
    return (
      <TableCell align="right" key={key} style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '200px'
      }}>
        <Tooltip title={t(`category.${isEnabled ? 'enabled' : 'disabled'}`) as string}>
          {icon}
        </Tooltip>
      </TableCell>);
  }; 
  
  const generateActionCell = (_cellMeta: RowCell<ArticleData>, rowData: ArticleData, _index?: number, key?: string) => {
    return (
      <TableCell align="right" key={key}>                
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <IconButton color="primary" aria-label="info" onClick={editArticleBtn(rowData.id)}>
              <ReceiptIcon/>
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <IconButton color="primary" aria-label="info" onClick={deleteArticleBtn(rowData.id)}>
              <DeleteIcon/>
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
    );
  };

  const generateHashTags = (key: keyof ArticleData, row: ArticleData) => {
    if (row[key]) {
      const tags = row[key].split(' ');
      return tags.map((el: string) => el.startsWith('#') ? el : `#${el}`).join(', ');
    }
    return '';
  };
  const { t } = useTranslation();
  
  const headCells: HeadCell<ArticleData>[] = [
    { id: 'id', align: 'center', numeric: false, width: 40, disablePadding: true, label: t('table.id') },
    { id: 'factText', numeric: false, disablePadding: false, label: t('table.fact') },
    { id: 'hashtag', numeric: false, disablePadding: false, label: t('table.hTags') },
    { id: 'isActive', numeric: true, disablePadding: false, label: t('table.active') },
    { id: 'actions', align: 'left', numeric: true, width: 100, disablePadding: false, label: t('table.actions') },
  ];
  
  const rowsCells: RowCell<ArticleData>[] = [
    { id: 'id', align: 'left', style: {maxWidth: '40px'}, disablePadding: true},
    { id: 'factText', align: 'left', hasTooltip: true, maxLength: 80},
    { id: 'hashtag', align: 'left', hasTooltip: true, maxLength: 80, formatter: generateHashTags},
    { id: 'isActive', align: 'right', cellGenerator: enabledCellGenerator('isActive')},
    { id: 'actions', cellGenerator: generateActionCell}
  ];

  const [isLoading, setLoading] = useState(true);
  const [articles, setArticles] = useState<ArticleData[]>();
  
  const [isAddOpen, setAddOpen] = useState(false);

  const [isEditOpen, setEditOpen] = useState(false);
  const [editingRowId, setEditingRowId] = useState<number | undefined>();
  const [selectedArticle, setSelectedArticle] = useState<IArticleMutationObject | undefined>();

  
  const [page, setPage] = useState(0);
  const [articlesCount, setArticlesCount] = useState(0);
  const [confirmDialog, setConfirmDialog] = useState<JSX.Element | undefined>();
  const [selectedCategory, setSelectedCategory] = useState<number | undefined>();
  const [categories, setCategories] = useState<ICategoriesResponse[]>();
  
  const rowsPerPage = 10;

  const refreshData = async () => {
    setLoading(true);
    const articles = await getArticles({page, rowsPerPage}, selectedCategory);
    if (articles.body && articles.body.data) {
      setArticles(articles.body.data);
      setArticlesCount(articles.body.dataCount);
    }
    if (!categories) {
      const cats = await getCategories({page: 0, rowsPerPage: 999});
      if (cats.body && cats.body.data) {
        setCategories(cats.body.data);
      }
    }
    setLoading(false);
  };

  const editArticleBtn = (rowId: number) => async () => {
    if (articles) {
      const articleById = articles.find(el => el.id === rowId);
      if (articleById) {
        setEditingRowId(rowId);
        setEditOpen(true);
        setSelectedArticle({
          id: articleById.id,
          articleText: articleById.articleText,
          factText: articleById.factText,
          hashtag: articleById.hashtag,
          image: articleById.image,
          externalLink: articleById.externalLink,
          isActive: articleById.isActive,
          categoryId: articleById.category.id
        });
      }
    }
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };


  const deleteArticleBtn = (rowId: number) => async () => {
    setLoading(true);
    const handleDelete = async () => {
      await deleteArticle(rowId);
      refreshData();
      setConfirmDialog(undefined);
    };

    const handleDeclineDelete = () => {
      setConfirmDialog(undefined);
    };
    setConfirmDialog(
      <ConfirmDialog isOpen={true} text={t('confirm.deleteArticle.text')} subText={t('confirm.deleteArticle.subText', {id: rowId})}  onAccept={handleDelete} onCancel={handleDeclineDelete}/>
    );
    setLoading(false);
  };

  const addArticleBtn = () => {
    setAddOpen(true);
  };

  const addArticleClose = () => {
    setAddOpen(false);
  };

  const saveNewArticle = async (data?: IArticleMutationObject) => {
    setLoading(true);
    if (data) {
      await addArticle(data);
      refreshData();
    }
    setLoading(false);
    setAddOpen(false);
  };

  const editNewArticle = async (data?: IArticleMutationObject) => {
    if (editingRowId) {
      setLoading(true);
      if (data) {
        await editArticle(data);
        refreshData();
      }
      setLoading(false);
      setEditOpen(false);
    }
  };

  useEffect(() => {
    refreshData();
  }, []);

  useEffect(() => {
    refreshData();
  }, [page, selectedCategory]);

  const handleCategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value as number === -1) {
      setSelectedCategory(undefined);
    } else {
      setSelectedCategory(event.target.value as number);
    }
  };

  return (
    <PageLayout lock={isLoading} aligment="left" title={t('knowledge.menuLabel')} buttons={[{icon: AddBoxIcon, action: addArticleBtn}]}>
      <div>
        <FormControl fullWidth style={{maxWidth: '300px'}}>
          <InputLabel id="category-selector-label">{t('categories.menuLabel')}</InputLabel>
          <Select
            labelId="category-selector-label"
            id="category-selector"
            value={selectedCategory}
            fullWidth
            onChange={handleCategoryChange}
          >
            <MenuItem key={'category_menu_item_none'} value={-1}>{t('commons.none')}</MenuItem>
            {categories?.map((category) => {
              return (<MenuItem key={`category_menu_item_${uuidv4()}`} value={category.id}>{category.categoryName}</MenuItem>);
            })}
          </Select>
        </FormControl>
        <AddEditDialog isOpen={isAddOpen} handleClose={addArticleClose} isEdit={false} handleSave={saveNewArticle} />
        <AddEditDialog isOpen={isEditOpen} handleClose={handleEditClose} isEdit={true} handleEdit={editNewArticle} inputData={selectedArticle}/>
        {confirmDialog} 
        <Separator/>
        <TableProvider<ArticleData> 
          headCells={headCells}
          rowCells={rowsCells}
          tableData={articles || []}
          selectedRows={[]}
          tableDataCount={articlesCount}
          paginated={true}
          paginationRange={[10]}
          rowsPerPage={rowsPerPage}
          pageChange={(newPage) => {
            setPage(newPage);
          }}
          page={page}
          rowsPerPageChange={() => {/** */}}
        />
      </div>
    </PageLayout>
  );
};
