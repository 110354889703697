import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HeadCell, TableProvider } from '../../Components/TableProvider';
import { RowCell } from '../../Components/TableRowProvider';
import { PageLayout } from '../../Themes/PageLayout';
import { addCategory, deleteCategory, getCategories, editCategory } from './Api';
import { CategoryData, ICategoriesDelResponse } from './types';
import { FormControlLabel, Grid, IconButton, Switch, TableCell, TextField, Tooltip } from '@material-ui/core';
import { TFunction } from 'i18next';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { green, red } from '@material-ui/core/colors';
import { MuiDialog } from '../../Components/Dialog';
import { IResponse } from '../../Utils/CommunicationService';
import { ConfirmDialog } from './commons/ConfirmDialog';
import { ImageUpload } from '../../Components/ImageUpload';
import { Separator } from '../../Components/Separator';

export const CategoriesPage: React.FC = () => {
  const enabledCellGenerator = (field: string) => (_cellMeta: RowCell<CategoryData>, rData: CategoryData, _index?: number, key?: string) => isEnabled(rData[field as keyof CategoryData], t, key);
  const isEnabled = (isEnabled: boolean, t: TFunction, key?: string): JSX.Element => {
    let icon;
    if (isEnabled) {
      icon = (<CheckCircleIcon style={{ right: 0, color: green[500] }}/>);
    } else {
      icon = (<HighlightOffIcon style={{ right: 0, color: red[500] }}/>);
    }
    return (
      <TableCell align="right" key={key} style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '200px'
      }}>
        <Tooltip title={t(`category.${isEnabled ? 'enabled' : 'disabled'}`) as string}>
          {icon}
        </Tooltip>
      </TableCell>);
  }; 
  
  const generateActionCell = (_cellMeta: RowCell<CategoryData>, rowData: CategoryData, _index?: number, key?: string) => {
    return (
      <TableCell align="right" key={key}>                
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <IconButton color="primary" aria-label="info" onClick={editCategoryBtn(rowData.id)}>
              <ReceiptIcon/>
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <IconButton color="primary" aria-label="info" onClick={deleteCategoryBtn(rowData.id)}>
              <DeleteIcon/>
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
    );
  };
  
  const { t } = useTranslation();
  
  const headCells: HeadCell<CategoryData>[] = [
    { id: 'id', align: 'center', numeric: false, width: 40, disablePadding: true, label: t('table.id')  },
    { id: 'categoryName', numeric: false, disablePadding: false, label: t('table.name')  },
    { id: 'isActive', numeric: true, disablePadding: false, label: t('table.active')  },
    { id: 'actions', align: 'left', numeric: true, width: 100, disablePadding: false, label: t('table.actions') },
  ];
  
  const rowsCells: RowCell<CategoryData>[] = [
    { id: 'id', align: 'left', style: {maxWidth: '40px'}, disablePadding: true},
    { id: 'categoryName', align: 'left', hasTooltip: true, maxLength: 29},
    { id: 'isActive', align: 'right', cellGenerator: enabledCellGenerator('isActive')},
    { id: 'actions', cellGenerator: generateActionCell}
  ];

  const [isLoading, setLoading] = useState(true);
  const [categories, setCategories] = useState<CategoryData[]>();
  
  const [dialogValueName, setDialogValueName] = useState('');
  const [isActive, setActive] = useState(false);
  const [isAddOpen, setAddOpen] = useState(false);

  const [isEditOpen, setEditOpen] = useState(false);
  const [editingRowId, setEditingRowId] = useState<number | undefined>();
  const [confirmDialog, setConfirmDialog] = useState<JSX.Element | undefined>();
  const [categoryImage, setCategoryImage] = useState<string | undefined>();


  const refreshData = async () => {
    setLoading(true);
    const cats = await getCategories({page: 0, rowsPerPage: 10});
    if (cats.body && cats.body.data) {
      setCategories(cats.body.data);
    }
    setLoading(false);
  };

  const editCategoryBtn = (rowId: number) => async () => {
    if (categories) {
      const categoryById = categories.find(el => el.id === rowId);
      if (categoryById) {
        setEditingRowId(rowId);
        setDialogValueName(categoryById.categoryName);
        setActive(categoryById.isActive);
        setCategoryImage(categoryById.image);
        setEditOpen(true);
      }
    }
  };

  const handleEditClose = () => {
    setActive(false);
    setDialogValueName('');
    setCategoryImage(undefined);
    setEditOpen(false);
  };

  const deleteCategoryBtn = (rowId: number) => async () => {
    setLoading(true);
    const handleDelete = async () => {
      setLoading(true);
      if (categories) {
        const deletedRow: IResponse<ICategoriesDelResponse> = await deleteCategory(rowId);
        if (deletedRow.body && deletedRow.body.affected) {
          const newArray = categories.slice(); 
          const indexForDelete = categories.findIndex(el => el.id === rowId);
          if (indexForDelete) {
            newArray.splice(indexForDelete, 1);
            setCategories(newArray);
          }
        }
        setConfirmDialog(undefined);
      }
      setLoading(false);
    };

    const handleDeclineDelete = () => {
      setConfirmDialog(undefined);
    };
    setConfirmDialog(
      <ConfirmDialog isOpen={true} text={t('confirm.deleteCategory.text')} subText={t('confirm.deleteCategory.subText', {id: rowId})}  onAccept={handleDelete} onCancel={handleDeclineDelete}/>
    );
    setLoading(false);
  };

  const addCategoryBtn = () => {
    setAddOpen(true);
  };

  const handleAddClose = () => {
    setActive(false);
    setDialogValueName('');
    setCategoryImage(undefined);
    setAddOpen(false);
  };

  const handleNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setDialogValueName(ev.target.value);
  };

  const handleActiveChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setActive(ev.target.checked);
  };

  const saveNewCategory = async () => {
    setLoading(true);
    const data = {
      categoryName: dialogValueName,
      image: categoryImage || '',
      isActive
    };
    const result = await addCategory(data);
    handleAddClose();
    setActive(false);
    setDialogValueName('');
    if (result.body) {
      const newRecord: CategoryData = result.body;
      if (categories) {
        const listCopy = categories.slice();
        listCopy.push(newRecord);
        setCategories(listCopy);
      } else {
        const newList = new Array<CategoryData>();
        newList.push(newRecord);
        setCategories(newList);
      }
    }
    setLoading(false);
  };

  const editNewCategory = async () => {
    if (editingRowId) {
      setLoading(true);
      const data = {
        categoryName: dialogValueName,
        image: categoryImage || '',
        isActive
      };
      const result = await editCategory(data, editingRowId);
      handleEditClose();
      setActive(false);
      setDialogValueName('');
      if (result.body) {
        if (categories) {
          const editedRecordId: number = categories.findIndex(el => el.id === editingRowId);
          if (editedRecordId !== -1) {
            const listCopy = categories.slice();
            listCopy[editedRecordId].categoryName = data.categoryName;
            listCopy[editedRecordId].isActive = data.isActive;
            setCategories(listCopy);
          }
        }
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    refreshData();
  }, []);

  const handleImageChange = (base64: string) => {
    let temp64 = base64;
    if (!temp64.startsWith('data:image/')) {
      temp64 = `data:image/png;base64,${temp64}`;
    }
    setCategoryImage(temp64);
  };


  return (
    <PageLayout lock={isLoading} aligment="left" title={t('categories.menuLabel')} buttons={[{icon: AddBoxIcon, action: addCategoryBtn}]}>
      <div>
        {confirmDialog}
        <MuiDialog width={500} open={isAddOpen} onClose={handleAddClose} title={t('categories.add.dialog.title')} buttons={[
          {
            label: t('categories.add.dialog.cancel'),
            action: handleAddClose,
          },
          {
            label: t('categories.add.dialog.save'),
            action: saveNewCategory,
          }
        ]} >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ImageUpload value={categoryImage || ''} onValueChange={handleImageChange}/>
            </Grid>
            <Grid item style={{width: '80%'}}>
              <TextField id="standard-basic" style={{width: '100%'}} label={t('categories.add.dialog.name.field')} variant="standard" value={dialogValueName} onChange={handleNameChange} />
            </Grid>
            <Grid item style={{
              justifyContent: 'center',
              display: 'flex',
              width: '20%'
            }}>
              <FormControlLabel
                style={{width: '100%'}}
                control={
                  <Switch checked={isActive} onChange={handleActiveChange} />
                }
                label={t('categories.add.dialog.active.field')}
              />
            </Grid>
          </Grid>
          
        </MuiDialog>
        <MuiDialog width={500} open={isEditOpen} onClose={handleEditClose} title={t('categories.edit.dialog.title', {id: editingRowId})} buttons={[
          {
            label: t('categories.edit.dialog.cancel'),
            action: handleEditClose,
          },
          {
            label: t('categories.edit.dialog.edit'),
            action: editNewCategory,
          }
        ]} >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ImageUpload value={categoryImage || ''} onValueChange={handleImageChange}/>
            </Grid>
            <Grid item style={{width: '70%'}}>
              <TextField id="standard-basic" style={{width: '100%'}} label={t('categories.edit.dialog.name.field')} variant="standard" value={dialogValueName} onChange={handleNameChange} />
            </Grid>
            <Grid item xs={4} style={{
              justifyContent: 'center',
              display: 'flex',
              width: '30%'
            }}>
              <FormControlLabel
                style={{width: '100%'}}
                control={
                  <Switch checked={isActive} onChange={handleActiveChange} />
                }
                label={t('categories.edit.dialog.active.field')}
              />
            </Grid>
          </Grid>
          
        </MuiDialog>
        <Separator/>
        <TableProvider<CategoryData> 
          headCells={headCells}
          rowCells={rowsCells}
          tableData={categories || []}
          selectedRows={[]}
          tableDataCount={categories?.length || 0}
          paginated={false}
        />
      </div>
    </PageLayout>
  );
};
