import { Checkbox, makeStyles, TableCell, TableRow, Tooltip} from '@material-ui/core';
import React from 'react';
import clsx from  'clsx';
import { useTranslation } from 'react-i18next';
import { HeadCell } from './TableProvider';
import { TFunction } from 'i18next';

export type CellGeneratorFunc<T> = (cellMeta: RowCell<T>, rowData: T, index?: number, key?: string) => JSX.Element;


export interface RowCell<T> {
  id: keyof T;
  cellGenerator?: CellGeneratorFunc<T>;
  align?: 'left' | 'right' | 'center';
  isCheckbox?: boolean;
  hasTooltip?: boolean;
  maxLength?: number;
  cellClassName?: string;
  emptySign?: string;
  style?: React.CSSProperties;
  disablePadding?: boolean;
  formatter?: (key: keyof T, row: T, t: TFunction) => JSX.Element | string;
}

interface TableRowProviderProps<T> {
  headCells: HeadCell<T>[],
  rowCells: RowCell<T>[],
  rowData: T,
  index: number,
  selectedRows: number[];
  itemSelector: (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => void; 
  rowDecorator?: React.CSSProperties
}


const useStyles = makeStyles(() => ({
  maxWidthOfCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px'
  }
}));

function validateRowCells<T extends {id: number}>(rowCells: RowCell<T>[], headCells: HeadCell<T>[]): boolean {
  let result = true;
  result = result && rowCells.length === headCells.length;
  const headIds = headCells.map(el => el.id);
  const rowIds = rowCells.map(el => el.id);
  headIds.forEach(el => {result = result && rowIds.includes(el);}); 
  rowIds.forEach(el => {result = result && headIds.includes(el);}); 
  return result;
}

export function TableRowProvider<T extends {id: number}> (props: TableRowProviderProps<T>): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const {headCells, rowCells, rowData, index, rowDecorator, selectedRows, itemSelector} = props;

  const generateCell = (
    cellMeta: RowCell<T>, 
    rData: T, 
    index: number, 
    selectedItems: number[], 
    checkboxCheckMet: (item: number) => (event: React.ChangeEvent<HTMLInputElement>) => void
  ) => {
    const key = `${cellMeta.id}-row-${index}`;
    if (cellMeta.cellGenerator) {
      return cellMeta.cellGenerator(cellMeta, rData, index, key);
    }
    if (cellMeta.isCheckbox) {
      const checkboxId = `enhanced-table-checkbox-${index}`;
      return (
        <TableCell 
          style={cellMeta.style} 
          component="td" 
          id={checkboxId} 
          scope="row" 
          key={key}
          padding="none">
          <Checkbox
            checked={selectedItems.includes(rData.id)}
            onChange={checkboxCheckMet(rData.id)}
            inputProps={{ 'aria-labelledby': checkboxId }}
          />
        </TableCell>
      );
    }
  
    const rawData = rowData[cellMeta.id] || cellMeta.emptySign || '';
    let data: string | T[keyof T] | JSX.Element = rawData;
    let cellData;
    if (cellMeta.maxLength && (rawData as string).length > cellMeta.maxLength) {
      const tempData = cellMeta.formatter ? cellMeta.formatter(cellMeta.id, rowData, t) : rowData[cellMeta.id];
      if (typeof tempData !== 'string' && typeof tempData !== 'number') {
        data = tempData as JSX.Element;
      } else {
        data = `${tempData.toString().substring(0, cellMeta.maxLength)}...`;
      }
    } else if (cellMeta.formatter) {
      data = cellMeta.formatter(cellMeta.id, rowData, t);
    }
    cellData = (<span>{data}</span>);
    if (cellMeta.hasTooltip) {
      const tempData = cellMeta.formatter ? cellMeta.formatter(cellMeta.id, rowData, t) : rawData;
      cellData = (<Tooltip title={tempData}>{cellData}</Tooltip>);
    }
  
    return (
      <TableCell 
        key={key}
        style={cellMeta.style} 
        align={cellMeta.align || 'left'} 
        className={cellMeta.cellClassName ? clsx(classes.maxWidthOfCell, cellMeta.cellClassName) : classes.maxWidthOfCell}
        padding={cellMeta.disablePadding ? 'none' : 'default'}
      >
        {cellData}
      </TableCell>
    );
  
  };
  return (
    <TableRow
      hover
      key={`row-${rowData.id}`}
      tabIndex={index}
      selected={false}
      style={rowDecorator}
    >
      {
        validateRowCells(rowCells, headCells) ? 
          rowCells.map(cellMeta => 
            generateCell(cellMeta, rowData, index, selectedRows, itemSelector)) :
          (
            <TableCell align="center">
              {t('table.tableRowsError')}
            </TableCell>
          )
      }
    </TableRow>
  );
}


