import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Button, makeStyles } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { useTranslation } from 'react-i18next';
import { LoginData, PasswordData } from '../types';
import { FormikSubmitHandler } from '../../../Components/types';

const useStyles = makeStyles(theme => ({
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

interface LoginFormProps {
  onSubmit: FormikSubmitHandler<LoginData | PasswordData>;
}

const initialValuesLogin = {
  email: '',
  password: ''
};



export const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }: LoginFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();


  const validationSchemaLogin = Yup.object().shape({
    email: Yup.string()
      .email(`${t('login.email')} must be an email`)
      .required(`${t('login.email')} is required field`),
    password: Yup.string().required(`${t('login.password')} is required field`)
  });

  return (
    <Formik<LoginData | PasswordData>
      initialValues={initialValuesLogin}
      validationSchema={validationSchemaLogin}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form noValidate>
          <Field
            component={TextField}
            id="email"
            name="email"
            autoComplete="email"
            label={t('login.email')}
            size="medium"
            fullWidth
            variant="outlined"
            margin="normal"
            required
          />

          <Field
            component={TextField}
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            label={t('login.password')}
            size="medium"
            fullWidth
            variant="outlined"
            margin="normal"
            required
          />
          <Button
            disabled={isSubmitting}
            type="submit"
            className={classes.submit}
            variant="contained"
            color="primary"
            size="large"
            fullWidth
          >
            {t('login.submit')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
