import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSession } from '../Contexts/SessionContext';
import { LoginPage } from '../Screens/LogiIn/LogiIn';
import { DashboardRouter } from './DashboardRouter';
// import { MainPage } from '../pages/MainPage';

export const AppRouter = (): JSX.Element => {
  const { isLogged } = useSession();
  return (
    <Switch>
      {isLogged() && (<>
        <Route exact path="/backoffice/*">
          <DashboardRouter />
        </Route>
        <Route path="/*">
          <Redirect to="/backoffice/" />
        </Route>
      </>)}
      {!isLogged() && (
        <>
          <Route path="/*">
            <Redirect to="/login" />
          </Route>
          <Route exact path="/login">
            <LoginPage/>
          </Route>
        </>
      )}
    </Switch>
  );
};
