import React, { useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Fade from '@material-ui/core/Fade';
import { useTranslation } from 'react-i18next';
import { LoginForm } from './Components/LoginForm';
import { LoginLayout } from '../../Themes/LoginLayout';
import { FormikSubmitHandler } from '../../Components/types';
import { LoginData, PasswordData } from './types';
import * as loginAPI from './Api';
import { useSession } from '../../Contexts/SessionContext';
import logo from '../../Themes/Assets/Images/startKlar.png';
import { FrameLoading } from '../../Components/FrameLoading';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  container: {
    minWidth: 360
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2, 0)
  },
  logoContent: {
    width: '150px',
    height: '100px'
  }
}));

export const LoginPage: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const { login } = useSession();
  const [hasError, setHasError] = useState(false);
  const [isLogging, setLogging] = useState<boolean>(false);

  const hideLoading = () => {
    setLogging(false);
  };

  const showLoading = () => {
    setLogging(true);
  };

  const handleSubmit: FormikSubmitHandler<LoginData | PasswordData> = async (data) => {
    showLoading();
    setHasError(false);
    const loginData = data as LoginData;
    const resp = await loginAPI.login(loginData);
    if (resp.statusCode === 404) {
      setHasError(true);
    } else if (resp.statusCode === 201 && resp.body && resp.body.token) {
      login({login: loginData.email}, resp.body.token);
      history.push('/backoffice/');
    }
    hideLoading();

  };
  return (
    <>
      <LoginLayout>
        <FrameLoading isLoading={isLogging} >
          <div className={classes.container} data-testid="login-page">
            <div className={classes.logoWrapper}>
              <Typography component="h1" variant="h4">
                <img src={logo}/>
              </Typography>
            </div>
            <Fade in={hasError} mountOnEnter unmountOnExit>
              <Alert severity="error">{t('login.wrongCredentials')}</Alert>
            </Fade>
            <LoginForm onSubmit={handleSubmit}/>
          </div>
        </FrameLoading>
      </LoginLayout>
    </>
  );
};
