import React, {ReactElement} from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { IconButton, makeStyles } from '@material-ui/core';
import { FrameLoading } from '../Components/FrameLoading';

interface PageLayoutProps {
  title?: string;
  lock?: boolean;
  aligment?: 'center' | 'left' | 'right';
  children?: ReactElement;
  buttons?: {icon: React.ElementType, action: () => void, disabled?: boolean}[]
}

export const PageLayout: React.FC<PageLayoutProps> = ({
  title,
  children,
  lock = false,
  aligment = 'center',
  buttons
}: PageLayoutProps) => {
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      paddingBottom: theme.spacing(2)
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: aligment,
      color: theme.palette.text.secondary
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.palette.primary.light
    }
  }));

  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FrameLoading isLoading={lock}>
          <Paper className={classes.paper}>
            {title && (
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    align="left"
                    noWrap
                    className={classes.root}
                  >
                    {title}
                  </Typography>
                </Grid>
                <Grid item xs={2} style={{textAlign: 'right'}}>
                  {buttons ? buttons.map(({icon: Icon, action, disabled}, index) => {
                    return (
                      <IconButton disabled={disabled}  key={`${title}-button-index-${index}`} color="primary" component="span" onClick={action}>
                        <Icon />
                      </IconButton>
                    );
                  }) : null}
                </Grid>
              </Grid>
            )}
            {children}
          </Paper>
        </FrameLoading>
      </Grid>
    </Grid>
  );
};
