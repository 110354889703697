import React, {ReactNode} from 'react';
import { createTheme, ThemeProvider, ThemeOptions } from '@material-ui/core/styles';

export type ThemeProps = {
  children: ReactNode,
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#F8EA1D',
    },
    secondary: {
      main: '#0F4450',
    },
    text: {
      primary: '#F8EA1D',
      secondary: '#DBD032',
      disabled: '#B7CEDD'
    },
    background: {
      paper: '#0e627d'
    }
  },
  appDrawer: {
    width: 240
  },
  dataTable: {
    minWidth: 650,
    maxHeight: 600,
    minHeight: 300
  },
} as ThemeOptions);

export const Theme: React.FC<ThemeProps> = ({children}: ThemeProps) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
