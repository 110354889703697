import { Grid, IconButton, TableCell } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HeadCell, TableProvider } from '../../../Components/TableProvider';
import { RowCell } from '../../../Components/TableRowProvider';
import { IContactAdd, IContactData } from '../types';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import { addContact, deleteContactAPI, getContacts } from '../Api';
import { FirstHelpAddContact } from './FirstHelpAddContact';

interface Params {
  setLoading: (isLoading: boolean) => void;
}

export const FirstHelpContactsPage: React.FC<Params> = ({setLoading}: Params) => {
  const [page, setPage] = useState<number>(0);
  const [contactsCount, setContactsCount] = useState<number>(0);
  const [contacts, setContacts] = useState<IContactData[]>([]);
  const [isAddOpen, setAddOpen] = useState<boolean>(false);

  const {t} = useTranslation();

  const generateContactCellTool = (_cellMeta: RowCell<IContactData>, rowData: IContactData, _index?: number, key?: string) => {
    return (
      <TableCell align="right" key={key}>                
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <IconButton 
              color="primary" aria-label="info" onClick={deleteContact(rowData.id)}>
              <DeleteIcon/>
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
    );
  };

  const gatherData = async () => {
    setLoading(true);
    const data = await getContacts({
      page,
      rowsPerPage: 10
    });
    if (data && data.body) {
      setContacts(data.body.data);
      setContactsCount(data.body.dataCount);
    }
    setLoading(false);
  };

  useEffect(() => {
    gatherData(); 
  }, [page]);

  const addNewContact = () => {
    setAddOpen(true);
  };

  const deleteContact = (id: number) => async () => {
    setLoading(true);
    await deleteContactAPI(id);
    await gatherData();
    setLoading(false);
  };

  const headContactCells: HeadCell<IContactData>[] = [
    { id: 'id', align: 'center', numeric: false, width: 40, disablePadding: true, label: t('table.id') },
    { id: 'landName', numeric: false, disablePadding: false, label: t('table.land') },
    { id: 'address', numeric: false, disablePadding: false, label: t('table.address') },
    { id: 'phoneNumber', numeric: false, disablePadding: false, label: t('table.phone') },
    { id: 'openingHours', numeric: false, disablePadding: false, label: t('table.hours') },
    { id: 'id', align: 'left', numeric: true, width: 100, disablePadding: false, label: t('table.actions') },
  ];
  
  const rowsContactCells: RowCell<IContactData>[] = [
    { id: 'id', align: 'left', style: {maxWidth: '40px'}, disablePadding: true},
    { id: 'landName', align: 'left', hasTooltip: true, maxLength: 128},
    { id: 'address', align: 'left', hasTooltip: true, maxLength: 10},
    { id: 'phoneNumber', align: 'left', hasTooltip: true, maxLength: 10},
    { id: 'openingHours', align: 'left', hasTooltip: true, maxLength: 10},
    { id: 'id', cellGenerator: generateContactCellTool}
  ];

  const handleAddClose = () => {
    setAddOpen(false);
  };

  const handleAddAccept = async (data: IContactAdd) => {
    handleAddClose();
    await addContact(data);
    await gatherData();
  };

  return (
    <Grid container>
      <FirstHelpAddContact isOpen={isAddOpen} handleClose={handleAddClose} handleAccept={handleAddAccept} />
      <Grid item xs={12}>
        <IconButton 
          color="primary" aria-label="info" onClick={addNewContact}>
          <AddBoxIcon/>
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <TableProvider<IContactData> 
          headCells={headContactCells}
          rowCells={rowsContactCells}
          tableData={contacts || []}
          selectedRows={[]}
          tableDataCount={contactsCount || 0}
          paginated={true}
          paginationRange={[10]}
          rowsPerPage={10}
          pageChange={(newPage: number) => {
            setPage(newPage);
          }}
          page={page}
          rowsPerPageChange={() => {/** */}}
        />
      </Grid>
    </Grid>
  );
};
