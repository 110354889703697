import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { ThemeProps } from './Theme';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bg: {
    background: `repeating-linear-gradient(
      -60deg,
      #0e627d,
      #0e627d 3px,
      #1b3038 3px,
      #1b3038 10px
    );`,
    filter: 'blur(2px)',
    position: 'fixed',
    left: '-5%',
    right: '-5%',
    top: '-5%',
    bottom: '-5%'
  },
  container: {
    padding: theme.spacing(2),
    background: '#0e627d',
    zIndex: 2
  }
}));

export const LoginLayout: React.FC<ThemeProps> = ({ children }: ThemeProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.bg}/>
      <Paper elevation={3} className={classes.container}>
        {children}
      </Paper>
    </div>
  );
};
