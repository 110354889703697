import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles } from '@material-ui/core';
import { RouteDefinition } from '../../Routes/types';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  tileFrame: {
    width: '150px',
    height: '100px',
    borderRadius: '5px',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    paddingTop: theme.spacing(3),
    background: '#F8EA1D',
    '&:active': {
      boxShadow: 'inset 0px 0px 2px 2px rgba(0,0,0,0.75)',
      '& .MuiGrid-root': {
        scale: 0.98
      }
    },
    margin: theme.spacing(2)
  },

  labelStyle: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 600
  },
  iconStyle: {
    color: 'rgba(0, 0, 0, 0.54)'
  }
}));

export const DashboardTile: React.FC<RouteDefinition> = ({label, path, Icon, disabled}: RouteDefinition) => {
  const classes = useStyles();
  const history = useHistory();
  const {t} = useTranslation();

  const handleRedirrect = () => {
    if (!disabled) {
      history.push(path);
    }
  }; 

  return (
    <div style={{opacity: disabled ? 0.5 : 1, cursor: disabled ? 'not-allowed' : 'pointer'}} className={classes.tileFrame} onClick={handleRedirrect}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Icon className={classes.iconStyle} fontSize={'large'}/>
        </Grid>
        <Grid item xs={12}>
          <span className={classes.labelStyle}>{t(label)}</span>
        </Grid>
      </Grid>
      
    </div>
  );
};