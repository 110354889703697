import { Grid, IconButton, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubViewProps } from '../../types';

import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import { Separator } from '../../../../Components/Separator';

interface FieldProps {
  onBlur: (finalValue: string) => void;
  defaultValue: string;
  text?: string;
}

export const WrappedTextField: React.FC<FieldProps> = ({onBlur, defaultValue, text}: FieldProps) => {
  const [value, setValue] = useState<string>(defaultValue);
  const {t} = useTranslation();

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setValue(ev.target.value);
  };

  const handleBlur = () => {
    onBlur(value);
  };

  return (
    <TextField 
      id="standard-basic" 
      style={{width: '100%'}} 
      label={text ? text : t('event.radio.option.text')} 
      variant="standard"
      value={value}
      margin="normal"
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export const RadioSubView: React.FC<SubViewProps> = ({onDataChange}: SubViewProps) => {
  const {t} = useTranslation();
  const [options, setOptions] = useState<Array<string>>([]);

  const addOption = () => {
    const newOptions = JSON.parse(JSON.stringify(options));
    newOptions.push('');
    setOptions(newOptions);
  };
  
  const handleOptionChange = (index: number) => (data: string) => {
    const newOptions = JSON.parse(JSON.stringify(options));
    newOptions[index] = data;
    setOptions(newOptions);
  };

  const deleteEl = (index: number) => () => {
    const newOptions: Array<string> = JSON.parse(JSON.stringify(options));
    newOptions.splice(index, 1);
    setOptions(newOptions);
  };

  useEffect(() => {
    onDataChange(options.join(';'));
  }, [options]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={10}> 
        <Typography
          component="h1"
          variant="body1"
          color="inherit"
          align="left"
          noWrap
        >
          {t('event.radio.options')}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <IconButton color="primary" component="span" onClick={addOption}>
          <AddBoxIcon />
        </IconButton>
      </Grid>
      {options.map((option: string, index: number) => {
        return (
          <>
            <Separator />
            <Grid item xs={10}>
              <WrappedTextField 
                onBlur={handleOptionChange(index)} 
                defaultValue={options[index]} 
                text={t('event.radio.option.text', {id: index + 1})}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton color="primary" aria-label="info" onClick={deleteEl(index)}>
                <DeleteIcon/>
              </IconButton>
            </Grid>
          </>
        );
      })}
    </Grid>
  );
};