import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from '../../Themes/PageLayout';
import { makeStyles } from '@material-ui/core';
import { availableRoutes } from '../../Routes/constants';
import { DashboardTile } from './DashboardTile';

const useStyles = makeStyles(() => ({
  chartRow: {
    display: 'flex', 
    flexDirection: 'row'
  },
}));

export const DashBoardPage: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(true);

  const refreshData = async () => {
    setLoading(true);
    /** data fetching */
    setLoading(false);
  };

  useEffect(() => {
    refreshData();
  }, []);


  return (
    <PageLayout lock={isLoading} title={t('dashboard.menuLabel')}>
      <div className={classes.chartRow}>
        {availableRoutes.filter(route => route.path !== '/backoffice/').map(route => <DashboardTile key={route.label} {...route} />)}
      </div>
    </PageLayout>
  );
};
