import React from 'react';

interface Props {hasMargin?: boolean}

export const Separator: React.FC<Props> = (props: Props) => {
  return (<div style={{
    width: '100%',
    borderBottom: '1px solid rgb(224, 224, 224)',
    marginTop: !props || props.hasMargin === true || props.hasMargin === undefined ? '20px' : undefined
  }}/>);
};