import React from 'react';
import { Switch } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { availableRoutes } from './constants';
import { NotFoundPage } from '../Screens/NotFound';
import { DashboardLayout } from '../Themes/DashboardLayout/DashboardLayout';
import { PrivateRoute } from './PrivateRoute';
import { useSession } from '../Contexts/SessionContext';
import fetchIntercept from 'fetch-intercept';

export const DashboardRouter: React.FC = () => {
  const { logout } = useSession();

  fetchIntercept.register({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: (resp: any) => {
      if (resp.status === 403 || resp.status === 401) {
        logout();
      }
      return resp;
    }
  });

  return (
    <DashboardLayout>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Switch>
          {availableRoutes.map(route => {
            const { Component, path, subpaths } = route;
            const routes = [
              <PrivateRoute exact path={path} key={path}>
                <Component />
              </PrivateRoute>
            ];
            if (subpaths) {
            // eslint-disable-next-line no-restricted-syntax
              for (const { subpath, SubComponent } of subpaths) {
                routes.push(
                  <PrivateRoute exact path={subpath} key={subpath}>
                    <SubComponent />
                  </PrivateRoute>
                );
              }
            }
            return routes;
          })}
          <PrivateRoute>
            <NotFoundPage />
          </PrivateRoute>
        </Switch>
      </MuiPickersUtilsProvider>
    </DashboardLayout>
  );
};
