import { Paper, Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Separator } from '../../../Components/Separator';
import { PageLayout } from '../../../Themes/PageLayout';
import { FirstHelpContactsPage } from './FirstHelpContactsPage';
import { FirstHelpSymptomsPage } from './FirstHelpSymptomsPage';

export const FirstHelpPage: React.FC = () => {
  const [tab, setTab] = useState<number>(1);
  const [isLoading, setLoading] = useState<boolean>(false);

  const {t} = useTranslation();

  const setLoad = (load: boolean) => {
    setLoading(load);
  };

  const getTabObject = () => {
    switch(tab) {
    case 0:
      return <FirstHelpContactsPage setLoading={setLoad}/>;
    case 1:
      return <FirstHelpSymptomsPage setLoading={setLoad}/>;
    }
  };

  return (
    <PageLayout lock={isLoading} aligment="left" title={t('firsthelp.menuLabel')}>
      <Paper square>
        <Separator />
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(ev, newValue: number) => {
            setTab(newValue);
          }}
          aria-label="disabled tabs example"
        >
          <Tab label={t('firsthelp.contacts')} disabled/>
          <Tab label={t('firsthelp.symptoms')} />
        </Tabs>
        
        <div style={{
          width: '100%',
          borderBottom: '1px solid rgb(224, 224, 224)'
        }}/>
        {getTabObject()}
      </Paper>
    </PageLayout>
  );
};
