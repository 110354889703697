/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Separator } from '../../../../Components/Separator';
import { SubViewProps } from '../../types';
import { WrappedTextField } from './RadioSubView';

export const RangeSubView: React.FC<SubViewProps> = ({onDataChange}: SubViewProps) => {
  const {t} = useTranslation();
  const [valueFrom, setValueFrom] = useState<number>(1);
  const [options, setOptions] = useState<Array<string>>([]);

  const handleFromChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValueFrom(event.target.value as number);
  };
  
  const range = (ns: number, ne: number) => [...(new Array(100).fill('', ns, ne).entries())].filter(([key, value]) => value === '').map(([key]) => key + 1);

  const generateRangeSelector = () => {
    const r = range(0, 100);
    return r.map(index => (
      <MenuItem key={`from-_item_${index}`} value={index}>{index}</MenuItem>
    ));
  };

  const generateRecords = () => {
    if (options.length < valueFrom) {
      const delta = valueFrom - options.length;
      const newOptions = JSON.parse(JSON.stringify(options));
      for (let i = 0; i < delta; i++) {
        newOptions.push('');
      }
      setOptions(newOptions);
    }
  };

  const handleOptionChange = (index: number) => (data: string) => {
    const newOptions = JSON.parse(JSON.stringify(options));
    newOptions[index] = data;
    setOptions(newOptions);
  };

  useEffect(() => {
    generateRecords();
  }, [valueFrom]);

  useEffect(() => {
    onDataChange(options.join(';'));
  }, [options]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={8}> 
        <FormControl fullWidth>
          <InputLabel id="from-range-selector-label">{t('event.question.range.from')}</InputLabel>
          <Select
            labelId="from-range-selector-label"
            id="from-range-selector"
            value={valueFrom}
            fullWidth
            onChange={handleFromChange}
          >
            {generateRangeSelector()}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}></Grid>
      {options.map((option: string, index: number) => {
        return (
          <>
            <Separator />
            <Grid item xs={12} key={`key-${index}`}>
              <WrappedTextField 
                onBlur={handleOptionChange(index)} 
                defaultValue={options[index]} 
                text={t('event.question.range.meaning', {range: index + 1})}
              />
            </Grid>
          </>
        );
      })}
    </Grid>
  );
};