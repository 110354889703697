import React from 'react';
import List from '@material-ui/core/List';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { availableRoutes } from '../../../../Routes/constants';
import { DashboardMenuItem } from './DashboardMenuItem';

export const DashboardMenu: React.FC = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  return (
    <List >
      {availableRoutes.map(({ label, path, Icon, subpaths, disabled }) => {
        let match = false;
        if (subpaths) {
          // eslint-disable-next-line
          match = subpaths.some(subPath => !!useRouteMatch(subPath.subpath));
        }
        return (
          <DashboardMenuItem
            disabled={disabled}
            key={path}
            to={path}
            primary={t(label)}
            icon={<Icon />}
            selected={pathname === path || match}
          />
        );
      })}
    </List>
  );
};
