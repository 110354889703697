import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { PageLayout } from '../../../Themes/PageLayout';
import SaveIcon from '@material-ui/icons/Save';
import { useEffect } from 'react';
import { ICategoriesResponse, IQuizQuestionResponse } from '../types';
import { editQuestion, getCategories, getQuestions } from '../Api';
import { FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch, TextField } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { Skeleton } from '@material-ui/lab';
import { AnswersList, AnswerType, IAnswer } from './AnswersList';
import RestorePageIcon from '@material-ui/icons/RestorePage';

export const EditPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch<{catId: string, questionId: string}>();
  const [isLoading, setLoading] = useState(true);
  const [isValid, setValid] = useState(false);
  const [questionId, setQuestionId] = useState<number>();
  const [categoryId, setCategoryId] = useState<number>();
  const [categories, setCategories] = useState<ICategoriesResponse[]>();
  const [answers, setAnswers] = useState<IAnswer[]>([]);
  const [newAnswers, setNewAnswers] = useState<IAnswer[]>();

  const [questionData, setQuestionData] = useState<IQuizQuestionResponse>();
  const [oldData, setOldData] = useState<IQuizQuestionResponse>();
  const [dataToSave, setDataToSave] = useState<IQuizQuestionResponse>();

  const saveBtn = async () => {
    if (dataToSave && dataToSave.quiz) {
      const resp = await editQuestion(dataToSave.id, {
        questionText: dataToSave.questionText,
        replyA: dataToSave.replyA,
        replyB: dataToSave.replyB,
        replyC: dataToSave.replyC,
        replyD: dataToSave.replyD,
        solution: dataToSave.solution,
        correctAnswer: dataToSave.correctAnswer,
        isActive: dataToSave.isActive,
        categoryId: dataToSave.quiz.id,
      });
      if (!resp.error) {
        setOldData(dataToSave);
        history.push(`/backoffice/quizes/edit/${dataToSave.quiz.id}/${dataToSave.id}`);
      }
    }
    setValid(false);
  };

  const revert = () => {
    if (oldData) {
      setQuestionData(oldData);
      setDataToSave(oldData);
      const letters: Array<AnswerType> = ['A', 'B', 'C', 'D'];
      const ans: IAnswer[] = [];
      letters.forEach((letter: AnswerType) => {
        const answer = oldData[`reply${letter}` as keyof IQuizQuestionResponse];
        if (answer) {
          ans.push({
            id: letter,
            text: answer as string,
            isCorrect: letter === oldData.correctAnswer
          });
        }
      });
      setAnswers(ans);
    }
    setTimeout(() => {
      setValid(false);
    },200);
  };

  const gatherData = async (cId: number, qId: number) => {
    const qs = await getQuestions({page: 0, rowsPerPage: 999}, cId);
    if (qs.body && qs.body.data) {
      const question = qs.body.data.find(question => question.id === qId);
      if (question) {
        setQuestionData(question);
        setOldData(question);
        const letters: Array<AnswerType> = ['A', 'B', 'C', 'D'];
        const ans: IAnswer[] = [];
        letters.forEach((letter: AnswerType) => {
          const answer = question[`reply${letter}` as keyof IQuizQuestionResponse];
          if (answer) {
            ans.push({
              id: letter,
              text: answer as string,
              isCorrect: letter === question.correctAnswer
            });
          }
        });
        setAnswers(ans);
        const cats = await getCategories({page: 0, rowsPerPage: 999});
        if (cats.body && cats.body.data) {
          setCategories(cats.body.data);
          setCategoryId(cId);
          setQuestionId(qId);
        }
        setValid(false);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const catId = Number(match.params.catId);
    const questionId = Number(match.params.questionId);
    gatherData(catId, questionId);
  }, []);

  const getQuestionDataCopy = () => {
    return JSON.parse(JSON.stringify(questionData));
  };

  const handleCategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCategoryId(event.target.value as number);
    const copy = getQuestionDataCopy();
    copy.quiz = categories?.find(el => el.id === event.target.value as number);
    setQuestionData(copy);
    validate(copy);
  };

  const handleFieldChange = (fieldName: keyof IQuizQuestionResponse) => (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (fieldName === 'isActive') {
      const copy = getQuestionDataCopy();
      copy.isActive = ev.target.checked;
      setQuestionData(copy);
      validate(copy);
    } else if (['questionText', 'solution'].includes(fieldName)) {
      const copy = getQuestionDataCopy();
      copy[fieldName] = ev.target.value;
      setQuestionData(copy);
      validate(copy);
    }
  };

  const getModelData = (ans?: IAnswer[]) => {
    const tempAns = ans || newAnswers;
    if (tempAns) {
      const correctOne = tempAns.find(el => el.isCorrect);
      return {
        replyA: tempAns[0] ? tempAns[0].text : undefined,
        replyB: tempAns[1] ? tempAns[1].text : undefined,
        replyC: tempAns[2] ? tempAns[2].text : undefined,
        replyD: tempAns[3] ? tempAns[3].text : undefined,
        correctAnswer: correctOne ? correctOne.id : undefined,
      };
    }
    return {};
  };

  const replyExists = (letter: AnswerType): boolean => {
    if (newAnswers) {
      const answer = newAnswers.find(el => el.id === letter);
      return !!answer;
    }
    return false;
  };

  const handleAnswersChange = (ans: IAnswer[]) => {
    setNewAnswers(ans);
    validate(undefined, getModelData(ans));
  };

  const validate = (data?:IQuizQuestionResponse, answerData?: {
    replyA?: string;
    replyB?: string;
    replyC?: string;
    replyD?: string;
    correctAnswer?: AnswerType;
  }): void => {
    const tempData = {...(data || questionData), ...(answerData || getModelData())};
    let vald = true;

    if (tempData?.quiz?.id === undefined) {
      vald = false;
    }

    if (tempData?.correctAnswer === '' || tempData?.correctAnswer === undefined) {
      vald = false;
    }

    if (tempData?.questionText === '' || tempData?.questionText === undefined) {
      vald = false;
    }

    if (tempData?.solution === '' || tempData?.solution === undefined) {
      vald = false;
    }

    if (tempData?.replyA === '' || tempData?.replyA === undefined) {
      vald = false;
    }

    if (tempData?.replyB === '' || tempData?.replyB === undefined) {
      if (replyExists('B')) {
        vald = false;
      }
    }

    if (tempData?.replyC === '' || tempData?.replyC === undefined) {
      if (replyExists('C')) {
        vald = false;
      }
    }

    if (tempData?.replyD === '' || tempData?.replyD === undefined) {
      if (replyExists('D')) {
        vald = false;
      }
    }

    if (vald) {
      setDataToSave(tempData as IQuizQuestionResponse );
    }

    setValid(vald);
  };

  return (
    <PageLayout lock={false} aligment="left" title={t('quiz.edit.menuLabel', {id: questionId || ''})} buttons={[
      {icon: RestorePageIcon, action: revert, disabled: !isValid},
      {icon: SaveIcon, action: saveBtn, disabled: !isValid}
    ]}>
      <div>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            {!isLoading && ( 
              <FormControl fullWidth >
                <InputLabel id="category-selector-label">{t('categories.menuLabel')}</InputLabel>
                <Select
                  labelId="category-selector-label"
                  id="category-selector"
                  value={categoryId}
                  fullWidth
                  onChange={handleCategoryChange}
                >
                  {categories?.map((category) => {
                    return (<MenuItem key={`category_menu_item_${uuidv4()}`} value={category.id}>{category.categoryName}</MenuItem>);
                  })}
                </Select>
              </FormControl>
            )}
            {isLoading && (
              <Skeleton variant="rect" style={{width: '100%'}} height={48} />
            )}
          </Grid>
          <Grid item xs={2}>
            {!isLoading && (
              <FormControlLabel
                style={{
                  width: '100%',
                  marginRight: '-48px',
                  marginLeft: '0px'
                }}
                control={
                  <Switch checked={questionData && questionData.isActive} onChange={handleFieldChange('isActive')} />
                }
                label={t('question.active.field')}
              />
            )}
            {isLoading && (
              <Skeleton variant="rect" style={{width: '100%'}} height={48} />
            )}
          </Grid>
          <Grid item xs={12}>
            {!isLoading && (
              <TextField 
                id="standard-basic-questionText" 
                style={{width: '100%'}} 
                label={t('question.questionText')} 
                value={questionData && questionData.questionText} 
                onChange={handleFieldChange('questionText')}
                multiline
                maxRows={8}
                minRows={2}
                variant={'outlined'}
              />
            )}
            {isLoading && (
              <Skeleton variant="rect" style={{width: '100%'}} height={75} />
            )}
          </Grid>
          <Grid item xs={12}>
            {!isLoading && (
              <TextField 
                id="standard-basic-solution" 
                style={{width: '100%'}} 
                label={t('question.solution')} 
                value={questionData && questionData.solution} 
                onChange={handleFieldChange('solution')}
                multiline
                maxRows={8}
                minRows={2}
                variant={'outlined'}
              />
            )}
            {isLoading && (
              <Skeleton variant="rect" style={{width: '100%'}} height={75} />
            )}
          </Grid>
          <AnswersList changeAnswers={handleAnswersChange} isLoading={isLoading} answersFromExt={answers}/>
        </Grid>
      </div>
    </PageLayout>
  );
};