import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback
} from 'react';
import { StorageProvider } from '../Services/StorageProvider/StorageProvider';
import { TokenValidator } from '../Services/TokenValidator/TokenValidator';

export interface IUserObject {
  login: string
}

interface SessionContextProps {
  initialized: boolean;
  getToken: () => string;
  getUser: () => IUserObject | undefined;
  isLogged: () => boolean;
  login: (userObject: IUserObject, tokens: string) => void;
  logout: VoidFunction;
}
const SessionContext = createContext<SessionContextProps | undefined>(
  undefined
);

interface SessionProviderProps {
  store: StorageProvider;
  tokenValidator: TokenValidator;
  children: JSX.Element;
}

export const SessionProvider: React.FC<SessionProviderProps> = ({
  children,
  store,
  tokenValidator
}: SessionProviderProps) => {
  const [initialized, setInitialized] = useState(false);
  const [token, setToken] = useState<string>('');
  const [user, setUser] = useState<IUserObject>();

  const login = useCallback(
    (userObject: IUserObject, token: string) => {
      const jwtToken = token;
      setToken(jwtToken);
      setUser(userObject);
      store.save(jwtToken);
      store.saveUser(userObject);
    },
    [store]
  );
  const logout = async () => {
    setToken('');
    setUser(undefined);
    store.remove();
    store.removeUser();
  };

  const getToken = useCallback((): string => token, [token]);
  const getUser = useCallback((): IUserObject | undefined => user, [user]);

  const isLogged = () => {
    if (!token) {
      return false;
    }
    return tokenValidator.isValid(token);
  };

  useEffect(() => {
    const storedToken = store.load();
    const storedUser = store.loadUser();
    if (storedToken && storedUser && tokenValidator.isValid(storedToken)) {
      login(storedUser, storedToken);
    }
    setInitialized(true);
  }, [login, store, tokenValidator]);

  return (
    <SessionContext.Provider
      value={{
        initialized,
        getToken,
        getUser,
        login,
        logout,
        isLogged
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = (): SessionContextProps => {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error('useSessionState must be used within a SessionProvider');
  }
  return context;
};
