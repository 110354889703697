import React, { ReactElement } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

interface FrameLoadingProps {
  isLoading: boolean;
  children: ReactElement;
  backdrop?: boolean;
  size?: number;
}

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative'
  },
  loaderSpiner: {
    alignSelf: 'center'
  },
  backdrop: {
    background: '#ccccccaa',
  },
  loaderRoot: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    },
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    zIndex: 999
  }
}));

export const FrameLoading: React.FC<FrameLoadingProps> = ({
  isLoading,
  children,
  backdrop,
  size
}: FrameLoadingProps) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {children}
      {isLoading && (
        <div className={clsx(classes.loaderRoot, {[`${classes.backdrop}`]: backdrop !== false})}>
          <CircularProgress color="primary" size={size}className={classes.loaderSpiner} />
        </div>
      )}
    </div>
  );
};
