import { IUserObject } from '../../Contexts/SessionContext';
import { StorageProvider } from './StorageProvider';

export class LocalStorageProvider implements StorageProvider {
  private lsKey = 'startklar-backoffice-panel';
  private lsUserKey = 'startklar-backoffice-panel-user-data';

  save(token: string): void {
    localStorage.setItem(this.lsKey, token);
  }

  load(): string | null {
    return localStorage.getItem(this.lsKey);
  }

  remove(): void {
    localStorage.removeItem(this.lsKey);
  }

  saveUser(userObject: IUserObject): void {
    localStorage.setItem(this.lsUserKey, JSON.stringify(userObject));
  }

  loadUser(): IUserObject | null {
    const user = localStorage.getItem(this.lsUserKey);
    if (user) {
      try {
        return JSON.parse(user);
      } catch (e) {
        /** This console log is for debug purposes */
        console.log((e as Error).message);
        return null;
      }
    }
    return null;
  }

  removeUser(): void {
    localStorage.removeItem(this.lsUserKey);
  }
}
