/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch, TextField } from '@material-ui/core';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MuiDialog } from '../../../Components/Dialog';
import { Separator } from '../../../Components/Separator';
import { addEventDateQuestion } from '../Api';
import { IEventDateQuestionAdd, TQuestionType } from '../types';
import { RadioSubView } from './QuestionTypes/RadioSubView';
import { RangeSubView } from './QuestionTypes/RangeSubView';

interface Props {
  isAssigned: boolean;
  isOpen: boolean;
  eventId?: number;
  handleClose: () => void;
  isTeacherQuestion: boolean;
}

const QUESTION_TYPES = [
  {
    type: 'DATE',
  },
  {
    type: 'AGE',
  },
  {
    type: 'SEX',
  },
  {
    type: 'RANGE',
  },
  {
    type: 'YESNO',
  },
  {
    type: 'RADIO',
  },
  {
    type: 'TEXT',
  },
];

export const AddQuestion: React.FC<Props> = (props: Props) => {
  const {
    isAssigned,
    isOpen,
    eventId,
    handleClose,
    isTeacherQuestion,
  } = props;

  const [dialogObject, setDialogObject] = useState<IEventDateQuestionAdd>({});
  const [errors, setErrors] = useState<Array<{field: keyof IEventDateQuestionAdd, message: string}>>([]);
  
  const [questionText, setQuestionText] = useState<string>('');
  const [selectedQuestionType, setSelectedQuestionType] = useState<TQuestionType | undefined>();
  const [answerOption, setAnswerOption] = useState<string | null>(null);
  const [isRequired, setRequired] = useState<boolean>(false);

  const {t} = useTranslation();

  const cleanup = () => {
    setDialogObject({});
    setErrors([]);
    setQuestionText('');
    setSelectedQuestionType(undefined);
    setAnswerOption(null);
    setRequired(false);
  };
  
  const _handleClose = () => {
    cleanup();
    handleClose();
  };

  useEffect(() => {
    setDialogObject({
      questionText,
      answerOption: answerOption || '',
      questionType: selectedQuestionType,
      isRequired,
      isTeacherQuestion,
      isActive: true
    });
  }, [questionText, selectedQuestionType, answerOption, isRequired]);

  const valid = () => {
    /** add verify of dialog object */
    return !errors.length;
  };

  const finishModal = async () => {
    console.log(dialogObject);
    if (valid()) {
      await addEventDateQuestion(dialogObject, isAssigned ? eventId : undefined);
      _handleClose();
    }
  };

  const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const type = event.target.value as TQuestionType;
    setSelectedQuestionType(type);
    if (['DATE', 'AGE', 'TEXT'].includes(type)) {
      setAnswerOption(null);
    } else if (type === 'SEX') {
      setAnswerOption('M;W;D');
    } else if (type === 'RANGE' || type === 'RADIO') {
      setAnswerOption('');
    } else if (type === 'YESNO') {
      setAnswerOption('Ja;Nein');
    }
  };

  const handleDataChange = (data: string) => {
    setAnswerOption(data);
  };

  const handleRqChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setRequired(ev.target.checked);
  };

  return (
    <MuiDialog width={500} open={isOpen} onClose={_handleClose} title={t('event.question.add.dialog.title')} buttons={[
      {
        label: t('knowledge.add.dialog.cancel'),
        action: _handleClose
      },
      {
        label: t('knowledge.add.dialog.save'),
        action: finishModal,
        disabled: !dialogObject
      }
    ]}>
      <Grid container spacing={1}>
        <Grid item xs={12}> 
          <TextField 
            id="standard-basic" 
            style={{width: '100%'}} 
            label={t('event.question.text')} 
            variant="standard" 
            value={questionText} 
            margin="normal"
            onChange={(ev: ChangeEvent<HTMLInputElement>) => setQuestionText(ev.target.value)}
          />
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth>
            <InputLabel id="question-type-selector-label">{t('event.question.type')}</InputLabel>
            <Select
              labelId="question-type-selector-label"
              id="question-type-selector"
              value={selectedQuestionType}
              fullWidth
              onChange={handleTypeChange}
            >
              {QUESTION_TYPES?.map((q) => {
                return (<MenuItem key={`question-type_item_${q.type}`} value={q.type}>{t(`event.question.t_${q.type.toLowerCase()}`)}</MenuItem>);
              })}
            </Select>
          </FormControl>
        </Grid> 
        <Grid item xs={4}>
          <FormControlLabel
            style={{width: '100%'}}
            control={
              <Switch checked={isRequired} onChange={handleRqChange} />
            }
            label={t('event.question.required')}
          />
        </Grid>
        <Grid item xs={12}>
          {selectedQuestionType === 'RADIO' && (
            <>
              <Separator />
              <RadioSubView onDataChange={handleDataChange} />
            </>
          )}
          {selectedQuestionType === 'RANGE' && (
            <>
              <Separator />
              <RangeSubView onDataChange={handleDataChange} />
            </>
          )}
        </Grid>
      </Grid>
    </MuiDialog>
  );
};