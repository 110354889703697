import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HeadCell, TableProvider } from '../../Components/TableProvider';
import { RowCell } from '../../Components/TableRowProvider';
import { PageLayout } from '../../Themes/PageLayout';
import { addImage, deleteImage, getImage, getImages } from './Api';
import { IEventImpressionData } from './types';
import { Grid, IconButton, TableCell } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { ConfirmDialog } from './commons/ConfirmDialog';
import { Separator } from '../../Components/Separator';
import SearchIcon from '@material-ui/icons/Search';
import { AddImpressionImageDialog } from './EventImpressions/AddImpressionImageDialog';


export const EventImpressionsPage: React.FC = () => {
 
  const generateActionCell = (_cellMeta: RowCell<IEventImpressionData>, rowData: IEventImpressionData, _index?: number, key?: string) => {
    return (
      <TableCell align="right" key={key}>                
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <IconButton color="primary" aria-label="info" onClick={showLarger(rowData.id)}>
              <SearchIcon/>
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <IconButton color="primary" aria-label="info" onClick={deleteImpressionBtn(rowData.id)}>
              <DeleteIcon/>
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
    );
  };

  const generateImgCell = (_cellMeta: RowCell<IEventImpressionData>, rowData: IEventImpressionData, _index?: number, key?: string) => {
    return (
      <TableCell align="center" key={key}>                
        <img src={rowData.imageThumbnail}/>
      </TableCell>
    );
  };

  const { t } = useTranslation();
  
  const headCells: HeadCell<IEventImpressionData>[] = [
    { id: 'id', align: 'center', numeric: false, width: 40, disablePadding: true, label: t('table.id') },
    { id: 'imageThumbnail', numeric: false, disablePadding: false, label: t('table.img') },
    { id: 'actions', align: 'left', numeric: true, width: 100, disablePadding: false, label: t('table.actions') },
  ];
  
  const rowsCells: RowCell<IEventImpressionData>[] = [
    { id: 'id', align: 'left', style: {maxWidth: '40px'}, disablePadding: true},
    { id: 'imageThumbnail', cellGenerator: generateImgCell},
    { id: 'actions', cellGenerator: generateActionCell}
  ];

  const [isLoading, setLoading] = useState(true);
  const [images, setImages] = useState<IEventImpressionData[]>();
  
  const [isAddOpen, setAddOpen] = useState(false);
  
  const [page, setPage] = useState(0);
  const [imagesCount, setImagesCount] = useState(0);
  const [confirmDialog, setConfirmDialog] = useState<JSX.Element | undefined>();
  const rowsPerPage = 10;

  const showLarger = (id: number) => async () => {
    const data = await getImage(id);
    if (data && data.body) {
      window.open(data.body.image, '_blank');
    }
  };

  const refreshData = async () => {
    setLoading(true);
    const data = await getImages({page, rowsPerPage});
    if (data && data.body) {
      setImages(data.body.data);
      setImagesCount(data.body.dataCount);
    }
    setLoading(false);
  };


  const deleteImpressionBtn = (rowId: number) => async () => {
    setLoading(true);
    const handleDelete = async () => {
      await deleteImage(rowId);
      refreshData();
      setConfirmDialog(undefined);
    };

    const handleDeclineDelete = () => {
      setConfirmDialog(undefined);
    };
    setConfirmDialog(
      <ConfirmDialog isOpen={true} text={t('confirm.deleteImage.text')} subText={t('confirm.deleteImage.subText', {id: rowId})}  onAccept={handleDelete} onCancel={handleDeclineDelete}/>
    );
    setLoading(false);
  };

  const addImpressionBtn = () => {
    setAddOpen(true);
  };

  const addImpressionClose = () => {
    setAddOpen(false);
  };

  const saveNewImpression = async (data: string) => {
    setLoading(true);
    if (data) {
      await addImage({
        image: data,
        isActive: true
      });
      refreshData();
    }
    setLoading(false);
    setAddOpen(false);
  };

  useEffect(() => {
    refreshData();
  }, []);

  useEffect(() => {
    refreshData();
  }, [page]);

  return (
    <PageLayout lock={isLoading} aligment="left" title={t('impressions.menuLabel')} buttons={[{icon: AddBoxIcon, action: addImpressionBtn}]}>
      <div>
        <AddImpressionImageDialog isOpen={isAddOpen} handleClose={addImpressionClose} handleSave={saveNewImpression} />
        {confirmDialog} 
        <Separator/>
        <TableProvider<IEventImpressionData> 
          headCells={headCells}
          rowCells={rowsCells}
          tableData={images || []}
          selectedRows={[]}
          tableDataCount={imagesCount}
          paginated={true}
          paginationRange={[10]}
          rowsPerPage={rowsPerPage}
          pageChange={(newPage) => {
            setPage(newPage);
          }}
          page={page}
          rowsPerPageChange={() => {/** */}}
        />
      </div>
    </PageLayout>
  );
};
