import React, { useEffect, useState } from 'react';
import { Skeleton } from '@material-ui/lab';
import { Separator } from '../../../Components/Separator';
import { Answer } from './Answer';
import { Grid, IconButton, Typography } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

export type AnswerType = 'A' | 'B' | 'C' | 'D';
export const ANSWER_ORDER: AnswerType[] = ['A' , 'B' , 'C' , 'D']; 

export interface IAnswer {
  id: AnswerType;
  text: string;
  isCorrect: boolean;
}

interface Props {
  isLoading: boolean;
  answersFromExt: IAnswer[];
  changeAnswers: (answers: IAnswer[]) => void;
}

export const AnswersList: React.FC<Props> = ({isLoading, answersFromExt, changeAnswers}: Props) => {
  const {t} = useTranslation();
  
  const [answers, setAnswers] = useState<IAnswer[]>([]);
  const [canAddMoreAnswers, setCanAddMoreAnswers] = useState<boolean>(false);

  const getAnswersCopy = (): IAnswer[]  => {
    return JSON.parse(JSON.stringify(answers));
  };

  const getCurrent = (id: AnswerType) => {
    return answers.find(el => el.id === id);
  };

  const getCurrentId = (id: AnswerType) => {
    return answers.findIndex(el => el.id === id);
  };

  useEffect(() => {
    changeAnswers(answers);
  }, [answers]);

  const addQuestion = () => {
    if (answers.length < 4) {
      const copy = getAnswersCopy();
      const newAnswer: IAnswer = {
        id: ANSWER_ORDER[copy.length],
        text: '',
        isCorrect: false
      };
      copy.push(newAnswer);
      setAnswers(copy);
    }
  };

  const onTextChange = (id: AnswerType, value: string) => {
    const copy = getAnswersCopy();
    const index = getCurrentId(id);

    if (index > -1) {
      copy[index].text = value;

      setAnswers(copy);
    }
  };

  const deleteQuestion = (id: AnswerType) => {
    const copy = getAnswersCopy();
    const current = getCurrent(id);
    let wasCorrect = false;
    if (current) {
      const index = answers.indexOf(current);
      if (current.isCorrect) {
        wasCorrect = true;
      }
      copy.splice(index, 1);
      copy.forEach((answer: IAnswer, index: number) => {
        answer.id = ANSWER_ORDER[index];
        if (wasCorrect) {
          if (index === 0) {
            answer.isCorrect = true;
          } else {
            answer.isCorrect = false;
          }
        }
      });
      setAnswers(copy);
    }
  };

  const switchFalseExcept = (id: AnswerType) => {
    const copy = getAnswersCopy();
    copy.forEach(answer => {
      if (answer.id === id) {
        answer.isCorrect = true;
      } else {
        answer.isCorrect = false;
      }
    });
    setAnswers(copy);
  };

  useEffect(() => {
    setAnswers(answersFromExt);
    
  }, [answersFromExt]);

  useEffect(() => {
    if (answers.length >= 0 && answers.length < 4) {
      setCanAddMoreAnswers(true);
    } else {
      setCanAddMoreAnswers(false);
    }
  }, [answers]);

  const generateAnswers = () => {
    return answers.map((answer, index) => (<Answer key={`anwser_paper_${uuidv4()}`} answer={answer} onDelete={deleteQuestion} onTextChange={onTextChange} onCorrectSwitch={switchFalseExcept} index={index}/>));
  };


  return (
    <>
      <Grid item xs={12}>
        {!isLoading && (
          <>
            <Typography variant={'h6'} component={'span'}>
              {t('question.questions.label')}
              <IconButton disabled={!canAddMoreAnswers} color="primary" aria-label="info" onClick={addQuestion}>
                <AddBoxIcon />
              </IconButton>
            </Typography>
          </>
        )}
        {isLoading && (
          <Skeleton variant="rect" style={{width: '100%'}} height={45} />
        )}
      </Grid>
      <Grid item xs={12}>
        <Separator />
        {isLoading ? (
          <Skeleton variant="rect" style={{width: '100%'}} height={200} />
        ) : (
          generateAnswers()
        )}
        <Separator />
      </Grid>
    </>
  );
};