import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';

import { DashboardDrawer } from './DashboardDrawer/DashboardDrawer';
import { DashboardToolbar } from './DashboardToolbar';

interface DashboardLayoutProps {
  children: JSX.Element
}

interface ThemeInterface extends Theme {
  appDrawer: {
    width: number | string
  };
}

const useStyles = makeStyles((theme: ThemeInterface) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: '#0e627d',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: theme.appDrawer.width,
    width: `calc(100% - ${theme.appDrawer.width}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  bg: {
    background: `repeating-linear-gradient(
      -60deg,
      #0e627d,
      #0e627d 3px,
      #1b3038 3px,
      #1b3038 10px
    );`,
    filter: 'blur(2px)',
    position: 'fixed',
    left: '-5%',
    right: '-5%',
    top: '-5%',
    bottom: '-5%'
  },
}));

export const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }: DashboardLayoutProps) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <DashboardToolbar isOpen={open} onOpen={handleDrawerOpen} />
      </AppBar>
      <DashboardDrawer isOpen={open} onClose={handleDrawerClose} />
      <main className={classes.content}>
        <div className={classes.bg}/>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          {children}
        </Container>
      </main>
    </div>
  );
};
