import { Grid, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MuiDialog } from '../../../Components/Dialog';
import { ISymptomAdd } from '../types';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleAccept: (data: ISymptomAdd) => void;
}

export const FirstHelpAddSymptom: React.FC<Props> = ({isOpen, handleClose, handleAccept}: Props) => {
  const {t} = useTranslation();

  const [dialogObject, setDialogObject] = useState<ISymptomAdd>({
    symptomText: '',
    isActive: true
  });

  const _cleanup = () => {
    setDialogObject({
      symptomText: '',
      isActive: true
    });
    setSymptom('');
  };

  const _handleClose = () => {
    if (handleClose) {
      handleClose();
    }
  };

  const _finishModal = () => {
    if (handleAccept && dialogObject) {
      handleAccept(dialogObject);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      _cleanup();
    }
  }, [isOpen]);

  // properties
  const [symptom, setSymptom] = useState<string>('');

  const handleSymptomTextChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.value.length === 0) {
      setSymptomError(t('firsthelp.contact.add.error.empty'));
    } else {
      setSymptomError('');
    }
    setSymptom(ev.target.value);
  };

  const [symptomError, setSymptomError] = useState<string>('');

  const updateObjectData = () => {
    setDialogObject({
      symptomText: symptom,
      isActive: true
    });
  };

  return (
    <MuiDialog width={500} open={isOpen} onClose={_handleClose} title={t('firsthelp.symptom.add.label')} buttons={[
      {
        label: t('knowledge.add.dialog.cancel'),
        action: _handleClose
      },
      {
        label: t('knowledge.add.dialog.save'),
        action: _finishModal,
        disabled: !dialogObject
      }
    ]}>
      <Grid container>
        <Grid item xs={12}>
          <TextField 
            id="standard-basic" 
            style={{width: '100%'}} 
            label={t('firsthelp.symptom.add.text')} 
            variant="standard" 
            value={symptom} 
            onChange={handleSymptomTextChange} 
            error={!!symptomError}
            onBlur={updateObjectData}
            helperText={
              symptomError && (<div  style={{color: '#f44336'}} >{symptomError}<br/></div>)
            }
          />
        </Grid>
      </Grid>
    </MuiDialog>
  );
};