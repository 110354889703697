
import React, {useEffect, useState} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import { IconButton } from '@material-ui/core';

interface ImageUploadProps {
  value: string;
  onValueChange: (newValue: string) => void;
}

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    border: '1px solid rgba(0, 0, 0, 0.42)',
    borderRadius: '4px',
    '&:hover': {
      border: '1px solid #F8EA1D'
    }
  },
  uploadButton: {
    right: '10px',
    position: 'absolute',
    top: '10px',
    zIndex: 2,
    background: '#0e627d',
    borderRadius: '50%',
    boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)'
  },
  loaderSpiner: {
    alignSelf: 'center'
  },
  backdrop: {
    background: '#ccccccaa',
  },
  loaderRoot: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    },
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    zIndex: 999
  }
}));

export const ImageUpload: React.FC<ImageUploadProps> = ({
  value,
  onValueChange
}: ImageUploadProps) => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [uuid, setUuid] = useState<string>();
  const [size, setSize] = useState(0);
  const [defaultSize, setDefaultSize] = useState(0);
  const [internalValue, setInternalValue] = useState<string>(value);

  useEffect(() => {
    setUuid(uuidv4());
  }, []);

  useEffect(() => {
    setInternalValue(value);
    setSizing();
  }, [value, defaultSize]);

  const setSizing = async () => {
    const imageSize = await getImageSize(value);
    const ratio = defaultSize/imageSize.width;
    setSize(imageSize.height*ratio);
  };

  useEffect(() => {
    setTimeout(async () => {
      if (uuid) {
        const el = document.getElementById(uuid);
        if (el) {
          if (!value) {
            setSize(el.offsetWidth/4);
          }
          setDefaultSize(el.offsetWidth);
          if (value) {
            setSizing();
          }
        }
      }
    }, 200);
  }, [uuid]);

  const onUpload = async () => {
    const fileUploadField = document.createElement('input');
    fileUploadField.type = 'file';
    fileUploadField.accept = '.png,.jpg,.bmp';
    fileUploadField.hidden = true;
    fileUploadField.onchange = async () => {
      setLoading(true);
      if (fileUploadField && fileUploadField.files && fileUploadField.files.length > 0) {
        const file = fileUploadField.files[0];
        if (file) {
          if(file.size > 20971520) {
            console.warn('file is too big');
          }
          const base = await toBase64(file);
          setInternalValue(base);
          onValueChange(base);
          const imageSize = await getImageSize(base);
          const ratio = defaultSize/imageSize.width;
          setSize(imageSize.height*ratio);
        }
      }
      setLoading(false);
    };
    document.body.appendChild(fileUploadField);
    fileUploadField.click();
    setTimeout(() => {
      document.body.removeChild(fileUploadField);
    }, 500);
  };

  const getImageSize = (image: string) => new Promise<{width: number, height: number}>((resolve) => {
    const img = new Image();
    img.onload = function() {
      resolve({
        width: img.width,
        height: img.height
      });
    };
    img.src = image;
  });

  const toBase64 = (file: File) => new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

  return (
    <div id={uuid} className={classes.container} style={{height: `${size}px`}}>
      <div className={classes.uploadButton}>
        <IconButton  color="primary" component="span" onClick={onUpload}>
          <AddPhotoAlternateIcon />
        </IconButton>
      </div>
      {
        internalValue && (
          <img style={{width: '100%', overflow: 'hidden', opacity: 0.7}} src={internalValue}/>
        )
      }
      {isLoading && (
        <div className={clsx(classes.loaderRoot, {[`${classes.backdrop}`]: true})}>
          <CircularProgress color="primary" size={64}className={classes.loaderSpiner} />
        </div>
      )}
    </div>
  );
};
