import { Button, Grid, IconButton, Paper, Tab, TableCell, Tabs, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { assignUnasignQuestion, deleteEventQuestion, getCSV, getEventAnswers, getEventDate, getEventDateSurveyQuestions } from '../Api';
import { IChartData, IEventAnswer, IEventAnswerData, IEventDateQuestionData, IEventDay, IQuestionPaginableResponse } from '../types';

import DeleteIcon from '@material-ui/icons/Delete';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import AddBoxIcon from '@material-ui/icons/AddBox';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { format, parse } from 'date-fns';
import { useRouteMatch } from 'react-router';
import { PageLayout } from '../../../Themes/PageLayout';
import { IPaginableResponse, IResponse } from '../../../Utils/CommunicationService';
import { Separator } from '../../../Components/Separator';
import { HeadCell, TableProvider } from '../../../Components/TableProvider';
import { RowCell } from '../../../Components/TableRowProvider';
import { AddQuestion } from './AddQuestion';
import { DataDialog } from './DataDialog';

export const EditEventDate: React.FC = () => {

  const [eventId, setEventId] = useState<number>();
  const [name, setName] = useState<string>('');
  const [date, setDate] = useState<Date | null>(null);
  const [dateFrom, setFromDate] = useState<Date | null>(null);
  const [dateTo, setToDate] = useState<Date | null>(null);

  const [isDataOpen, setDataOpen] = useState<boolean>(false);
  const [dataQuestionText, setDataQuestionText] = useState<string>('');
  const [chartData, setChartData] = useState<IChartData[] | undefined>();
  const [pickedQuestionId, setPickedQuestionId] = useState<number | undefined>();
  
  const match = useRouteMatch<{evId: string}>();
  const {t} = useTranslation();

  const [assignedQs, setAssignedQs] = useState<IEventDateQuestionData[]>();
  const [answers, setAnswers] = useState<IEventAnswerData[]>();
  const [unassignedQs, setUnassignedQs] = useState<IEventDateQuestionData[]>();
  const [assignedQsCount, setAssignedQsCount] = useState<number>(0);
  const [unassignedQsCount, setUnassignedQsCount] = useState<number>(0);
  const [answersCount, setAnswersCount] = useState<number>(0);

  const [assignedPage, setAssignedPage] = useState<number>(0);
  const assignedRowsPerPage = 10;
  const [unassignedPage, setUnassignedPage] = useState<number>(0);
  const unassignedRowsPerPage = 10;
  const [answersPage, setAnswersPage] = useState<number>(0);
  const answersRowsPerPage = 10;

  
  const [isTeacher, setIsTeacher] = useState<boolean>(false);
  const [isAnswers, setIsAnswers] = useState<boolean>(false);

  const [mainDataLoaded, setMainDataLoaded] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);

  const [isAssignedDialog, setAssignedDialog] = useState<boolean>(false);
  const [isAddOpen, setAddOpen] = useState<boolean>(false);


  const moveUnassigned = (index: number) => async () => {
    if (eventId) {
      setLoading(true);
      await assignUnasignQuestion(eventId, index, 'assign');
      await gatherQsData();
      setLoading(false);
    }
  };

  const deleteUnassignedQuestionBtn = (index: number) => async () => {
    setLoading(true);
    await deleteEventQuestion(index);
    await gatherQsData();
    setLoading(false);
  };

  const moveAssigned = (index: number) => async () => {
    if (eventId) {
      setLoading(true);
      await assignUnasignQuestion(eventId, index, 'unassign');
      await gatherQsData();
      setLoading(false);
    }
  };

  const deleteAssignedQuestionBtn = (index: number) => async () => {
    setLoading(true);
    await deleteEventQuestion(index);
    await gatherQsData();
    setLoading(false);
  };

  const adNewQuestion = (isAssigned: boolean) => () => {
    setAssignedDialog(isAssigned);
    setAddOpen(true);
  };

  const generateUnassignedActionCell =(_cellMeta: RowCell<IEventDateQuestionData>, rowData: IEventDateQuestionData, _index?: number, key?: string) => {
    return (
      <TableCell align="right" key={key}>                
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <IconButton 
              disabled={isDatePast()} color="primary" aria-label="info" onClick={deleteUnassignedQuestionBtn(rowData.id)}>
              <DeleteIcon/>
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <IconButton 
              disabled={isDatePast()} color="primary" aria-label="info" onClick={moveUnassigned(rowData.id)}>
              <ArrowForwardIosIcon/>
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
    );
  };

  const generateAssignedDeleteActionCell =(_cellMeta: RowCell<IEventDateQuestionData>, rowData: IEventDateQuestionData, _index?: number, key?: string) => {
    return (
      <TableCell align="right" key={key}>                
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <IconButton 
              disabled={isDatePast()} color="primary" aria-label="info" onClick={deleteAssignedQuestionBtn(rowData.id)}>
              <DeleteIcon/>
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
    );
  };

  const generateAssignedMoveActionCell =(_cellMeta: RowCell<IEventDateQuestionData>, rowData: IEventDateQuestionData, _index?: number, key?: string) => {
    return (
      <TableCell align="right" key={key}>                
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <IconButton 
              disabled={isDatePast()} color="primary" aria-label="info" onClick={moveAssigned(rowData.id)}>
              <ArrowBackIosIcon/>
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
    );
  };

  const generateAnswerCellTool = (_cellMeta: RowCell<IEventAnswerData>, rowData: IEventAnswerData, _index?: number, key?: string) => {
    return (
      <TableCell align="right" key={key}>                
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Button color={'primary'} onClick={handleDataShow(rowData.id, rowData.questionText, rowData.columns)} variant={'contained'}>data</Button>
          </Grid>
        </Grid>
      </TableCell>
    );
  };

  const headUnassignedCells: HeadCell<IEventDateQuestionData>[] = [
    { id: 'id', align: 'center', numeric: false, width: 40, disablePadding: true, label: t('table.id')},
    { id: 'questionText', numeric: false, disablePadding: false, label: t('table.question') },
    { id: 'questionType', numeric: false, disablePadding: false, label: t('table.type') },
    { id: 'actions', align: 'left', numeric: true, width: 100, disablePadding: false, label: t('table.actions') },
  ];
  
  const rowsUnassignedCells: RowCell<IEventDateQuestionData>[] = [
    { id: 'id', align: 'left', style: {maxWidth: '40px'}, disablePadding: true},
    { id: 'questionText', align: 'left', hasTooltip: true, maxLength: 128},
    { id: 'questionType', align: 'left', hasTooltip: true, maxLength: 10},
    { id: 'actions', cellGenerator: generateUnassignedActionCell}
  ];

  const headAssignedCells: HeadCell<IEventDateQuestionData>[] = [
    { id: 'actions', align: 'left', numeric: true, width: 40, disablePadding: false, label: '' },
    { id: 'id', align: 'center', numeric: false, width: 40, disablePadding: true, label: t('table.id') },
    { id: 'questionText', numeric: false, disablePadding: false, label: t('table.question') },
    { id: 'questionType', numeric: false, disablePadding: false, label: t('table.type') },
    { id: 'actions', align: 'left', numeric: true, width: 40, disablePadding: false, label: t('table.actions') },
  ];
  
  const rowsAssignedCells: RowCell<IEventDateQuestionData>[] = [
    { id: 'actions', cellGenerator: generateAssignedMoveActionCell},
    { id: 'id', align: 'left', style: {maxWidth: '40px'}, disablePadding: true},
    { id: 'questionText', align: 'left', hasTooltip: true, maxLength: 128},
    { id: 'questionType', align: 'left', hasTooltip: true, maxLength: 10},
    { id: 'actions', cellGenerator: generateAssignedDeleteActionCell},
  ];

  const headAnswersCells: HeadCell<IEventAnswerData>[] = [
    { id: 'id', align: 'center', numeric: false, width: 40, disablePadding: true, label: t('table.id') },
    { id: 'questionText', numeric: false, disablePadding: false, label:  t('table.question') },
    { id: 'questionType', numeric: false, disablePadding: false, label: t('table.type') },
    { id: 'totalAnswers', numeric: false, disablePadding: false, label: t('table.answers') },
    { id: 'id', align: 'left', numeric: true, width: 100, disablePadding: false, label: t('table.actions') },
  ];
  
  const rowsAnswersCells: RowCell<IEventAnswerData>[] = [
    { id: 'id', align: 'left', style: {maxWidth: '40px'}, disablePadding: true},
    { id: 'questionText', align: 'left', hasTooltip: true, maxLength: 128},
    { id: 'questionType', align: 'left', hasTooltip: true, maxLength: 10},
    { id: 'totalAnswers', align: 'left', hasTooltip: true, maxLength: 10},
    { id: 'id', cellGenerator: generateAnswerCellTool}
  ];

  const handleDataShow = (questionId: number, questionDataText: string, chartData?: IChartData[] ) => () => {
    setPickedQuestionId(questionId);
    setChartData(chartData);
    setDataQuestionText(questionDataText);
    setDataOpen(true);
  };

  const handleDataWidnowClose = () => {
    setChartData(undefined);
    setDataOpen(false);
  };

  const gatherData = async (evId: number) => { 
    setLoading(true);
    const data: IResponse<IEventDay> = await getEventDate(evId);
    if (data.body) {
      setDate(parse(data.body.eventDate, 'yyyy-MM-dd', new Date()));
      setName(data.body.eventName);
    }
    setEventId(evId);
    setMainDataLoaded(true);
    setLoading(false);
  };

  const gatherQsData = async () => {
    setLoading(true);
    if (eventId) {
      const data: IResponse<IQuestionPaginableResponse> = await getEventDateSurveyQuestions(
        eventId,
        {
          page: unassignedPage,
          rowsPerPage: unassignedRowsPerPage,
        },
        {
          page: assignedPage,
          rowsPerPage: assignedRowsPerPage,
        },
        isTeacher
      );
      if (data.body) {
        if (data.body.assigned && data.body.assigned.data) {
          setAssignedQs(data.body.assigned.data);
          setAssignedQsCount(data.body.assigned.dataCount);
        }
        if (data.body.unassigned && data.body.unassigned.data) {
          setUnassignedQs(data.body.unassigned.data);
          setUnassignedQsCount(data.body.unassigned.dataCount);
        }
      }
    }
    setLoading(false);
  };

  const gatherAnswersData = async () => {
    setLoading(true);
    if (eventId) {
      const data: IResponse<IPaginableResponse<IEventAnswer>> = await getEventAnswers(
        eventId,
        {
          page: unassignedPage,
          rowsPerPage: unassignedRowsPerPage,
        },
        isTeacher
      );
      if (data && data.body) {
        const paginadedData = data.body;
        setAnswersCount(paginadedData.dataCount);
        setAnswers(paginadedData.data.map(el => ({
          id: el.questionId,
          ...el
        })));
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const evId = Number(match.params.evId);
    gatherData(evId);
  }, []);

  useEffect(() => {
    if (mainDataLoaded && !isAnswers) {
      gatherQsData();
    }
  }, [mainDataLoaded, assignedPage, unassignedPage, isTeacher, isAnswers]);

  useEffect(() => {
    if (mainDataLoaded && isAnswers) {
      gatherAnswersData();
    }
  }, [mainDataLoaded, answersPage, isTeacher, isAnswers]);

  const handleNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setName(ev.target.value);
  };

  const handleDateChange = (date: Date | null) => {
    setDate(date);
  };

  const handleDateFromChange = (date: Date | null) => {
    setFromDate(date);
  };

  const handleDateToChange = (date: Date | null) => {
    setToDate(date);
  };
  
  const handleCloseAdd = async () => {
    setAddOpen(false);
    setAssignedDialog(false);
    await gatherQsData();
  };

  const isDatePast = () => {
    if (date && (+date < +new Date())) {
      return true;
    }
    return false;
  };

  const getTabId = () => {
    if (!isTeacher && !isAnswers) {
      return 0;
    } else if (isTeacher && !isAnswers) {
      return 1;
    } else if (!isTeacher && isAnswers) {
      return 3;
    } else if (isTeacher && isAnswers) {
      return 4;
    }
  };

  const downloadData = async () => {
    if (dateFrom && dateTo) {
      setLoading(true);
      const data = await getCSV(format(dateFrom, 'yyyy-MM-dd'), format(dateTo, 'yyyy-MM-dd'));
      
      if (data && data.rawBody) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(data.rawBody));
        element.setAttribute('download', `${format(dateFrom, 'yyyy-MM-dd')}-${format(dateTo, 'yyyy-MM-dd')}-startKlar.csv`);
  
        element.style.display = 'none';
        document.body.appendChild(element);
  
        element.click();
  
        document.body.removeChild(element);
      }
      
      setLoading(false);
    }
  };

  return (
    <PageLayout lock={isLoading} aligment="left" title={t('event.edit.menuLabel', {id: eventId || ''})}>
      <>
        <DataDialog isOpen={isDataOpen} questionText={dataQuestionText} chartsData={chartData} questionId={pickedQuestionId || -1} eventId={eventId || -1} handleClose={handleDataWidnowClose} />
        <AddQuestion isAssigned={isAssignedDialog} isOpen={isAddOpen} eventId={eventId} handleClose={handleCloseAdd} isTeacherQuestion={isTeacher}/>
        <div style={{width: '100%', maxWidth: '1200px'}}>
          <Grid container spacing={1}>
            <Grid item style={{width: '35%'}}>
              <TextField 
                id="standard-basic" 
                style={{width: '100%'}} 
                label={t('event.name.label')} 
                variant="standard" value={name} 
                margin="normal"
                onChange={handleNameChange} 
                disabled={isDatePast()}
              />
            </Grid>
            <Grid item style={{
              width: '15%'
            }}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="yyyy-MM-dd"
                margin="normal"
                id="date-picker-inline"
                label={t('event.date.label')}
                value={date}
                onChange={handleDateChange}
                style={{width: '100%'}}
                disabled={isDatePast()}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
            <Grid item style={{
              width: '15%'
            }}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="yyyy-MM-dd"
                margin="normal"
                id="date-picker-inline"
                label={t('event.answers.from')}
                value={dateFrom}
                onChange={handleDateFromChange}
                style={{width: '100%'}}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
            <Grid item style={{
              width: '15%'
            }}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="yyyy-MM-dd"
                margin="normal"
                id="date-picker-inline"
                label={t('event.answers.to')}
                value={dateTo}
                onChange={handleDateToChange}
                style={{width: '100%'}}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
            <Grid item style={{width: '20%', position: 'relative'}}>
              <Button 
                variant="outlined"
                onClick={downloadData}
                disabled={!dateFrom || !dateTo}
                style={{bottom: '10px', position: 'absolute'}}
              >{t('event.answers.download')}</Button>
            </Grid>
          </Grid>
        </div>
        <Separator />
        <Paper square>
          <Tabs
            value={getTabId()}
            indicatorColor="primary"
            textColor="primary"
            onChange={(ev, newValue: number) => {
              if(newValue === 0) {
                setIsTeacher(false);
                setIsAnswers(false);
              } else if (newValue === 1) {
                setIsTeacher(true);
                setIsAnswers(false);
              } else if (newValue === 3) {
                setIsTeacher(false);
                setIsAnswers(true);
              } else if (newValue === 4) {
                setIsTeacher(true);
                setIsAnswers(true);
              }
            }}
            aria-label="disabled tabs example"
          >
            <Tab label="Student" />
            <Tab label="Teacher" />
            <Tab disabled={true} style={{
              width: '100%',
              maxWidth: 'calc(100% - 160px * 4)',
            }}/>
            {isDatePast() && (
              <Tab label="Answers (S)" />
            )}
            {isDatePast() && (
              <Tab label="Answers (T)" />
            )}
          </Tabs>
          
          <div style={{
            width: '100%',
            borderBottom: '1px solid rgb(224, 224, 224)'
          }}/>
          {((!isAnswers && isTeacher) || (!isAnswers && !isTeacher)) && (
            <>
              <Grid container spacing={1}>
                <Grid item style={{width: '50%', borderRight: '1px solid rgb(224, 224, 224)', marginTop: '3px'}}>
                  <Grid container spacing={1}>
                    <Grid item style={{width: '90%'}}>
                      <Typography
                        component="h1"
                        variant="body1"
                        color="inherit"
                        align="left"
                        noWrap
                        style={{lineHeight: '48px', paddingLeft: '12px'}}
                      >
                        {t('event.questions.unassigned')}
                      </Typography>
                    </Grid>
                    <Grid item style={{width: '10%'}}>
                      <IconButton 
                        disabled={isDatePast()} color="primary" aria-label="info" onClick={adNewQuestion(false)}>
                        <AddBoxIcon/>
                      </IconButton>
                  
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item style={{width: '50%'}}>
                  <Grid container spacing={1}>
                    <Grid item style={{width: '90%'}}>
                      <Typography
                        component="h1"
                        variant="body1"
                        color="inherit"
                        align="left"
                        noWrap
                        style={{lineHeight: '48px', paddingLeft: '12px'}}
                      >
                        {t('event.questions.assigned')}
                      </Typography>
                    </Grid>
                    <Grid item style={{width: '10%'}}>
                      <IconButton 
                        disabled={isDatePast()} color="primary" aria-label="info" onClick={adNewQuestion(true)}>
                        <AddBoxIcon/>
                      </IconButton>
                  
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item style={{width: '50%', borderRight: '1px solid rgb(224, 224, 224)'}}>
                  <TableProvider<IEventDateQuestionData> 
                    headCells={headUnassignedCells}
                    rowCells={rowsUnassignedCells}
                    tableData={unassignedQs || []}
                    selectedRows={[]}
                    tableDataCount={unassignedQsCount || 0}
                    paginated={true}
                    paginationRange={[unassignedRowsPerPage]}
                    rowsPerPage={unassignedRowsPerPage}
                    pageChange={(newPage: number) => {
                      setUnassignedPage(newPage);
                    }}
                    page={unassignedPage}
                    rowsPerPageChange={() => {/** */}}
                  />
                </Grid>
                <Grid item style={{width: '50%'}}>
                  <TableProvider<IEventDateQuestionData> 
                    headCells={headAssignedCells}
                    rowCells={rowsAssignedCells}
                    tableData={assignedQs || []}
                    selectedRows={[]}
                    tableDataCount={assignedQsCount || 0}
                    paginated={true}
                    paginationRange={[assignedRowsPerPage]}
                    rowsPerPage={assignedRowsPerPage}
                    pageChange={(newPage: number) => {
                      setAssignedPage(newPage);
                    }}
                    page={assignedPage}
                    rowsPerPageChange={() => {/** */}}
                  />
                </Grid>
              </Grid>
            </>
          )}
          {((isAnswers && isTeacher) || (isAnswers && !isTeacher)) && (
            <TableProvider<IEventAnswerData> 
              headCells={headAnswersCells}
              rowCells={rowsAnswersCells}
              tableData={answers || []}
              selectedRows={[]}
              tableDataCount={answersCount || 0}
              paginated={true}
              paginationRange={[answersRowsPerPage]}
              rowsPerPage={answersRowsPerPage}
              pageChange={(newPage: number) => {
                setAnswersPage(newPage);
              }}
              page={answersPage}
              rowsPerPageChange={() => {/** */}}
            />
          )}
        </Paper>
      </>
    </PageLayout>
  );
};