import React from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Theme} from './Themes/Theme';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSession } from './Contexts/SessionContext';
import { AppRouter } from './Routes/AppRouter';

const App = (): JSX.Element => {
  const { initialized } = useSession();

  return (
    <Router basename="/">
      <Theme>
        {initialized ? (
          <AppRouter />
        ) : (
          <Backdrop open>
            <CircularProgress color="primary" size={80} />
          </Backdrop>
        )}
      </Theme>
    </Router>
  );
};
export default App;
