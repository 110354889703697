import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MuiDialog } from '../../../Components/Dialog';

interface ConfirmDialogProps {
  isOpen: boolean;
  text: string;
  subText: string;
  onCancel: () => void;
  onAccept: () => void;
  yesLabel?: string;
  noLabel?: string;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({isOpen, text, subText, onCancel, onAccept, yesLabel, noLabel}: ConfirmDialogProps) => {
  const {t} = useTranslation();

  const cancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const accept = () => {
    if (onAccept) {
      onAccept();
    }
  };
 

  return (
    <MuiDialog width={500} open={isOpen} onClose={cancel} title={text} buttons={[
      {
        label: yesLabel || t('dialog.no'),
        action: cancel
      },
      {
        label: noLabel || t('dialog.yes'),
        action: accept
      }
    ]} >
      <Typography variant="subtitle1" component="span">
        {subText}
      </Typography>
      
    </MuiDialog>
  );
};