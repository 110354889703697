import { Grid, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MuiDialog } from '../../../Components/Dialog';
import { addEventDate } from '../Api';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { format } from 'date-fns';
import { useHistory } from 'react-router';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

export const AddEventDate: React.FC<Props> = ({handleClose, isOpen}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [date, setDate] = useState<Date | null>(null);
  
  const {t} = useTranslation();
  const history = useHistory();
  
  const handleAddClose = () => {
    setOpen(false);
    handleClose();
  };

  const saveNewEvent = async () => {
    if (date && name) {
      const newEvent = await addEventDate(name, format(date, 'yyyy-MM-dd'));
      if (newEvent.body) {
        history.push(`/backoffice/events/${newEvent.body.id}`);
      }
    }
  };

  const handleNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setName(ev.target.value);
  };

  const handleDateChange = (date: Date | null) => {
    setDate(date);
  };

  useEffect(() => {
    if (isOpen) {
      setName('');
      setDate(null);
    }
    setOpen(isOpen);
  }, [isOpen]);

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);


  
  return (
    <MuiDialog width={500} open={open} onClose={handleAddClose} title={t('event.add.dialog.title')} buttons={[
      {
        label: t('categories.add.dialog.cancel'),
        action: handleAddClose,
      },
      {
        label: t('categories.add.dialog.save'),
        action: saveNewEvent,
      }
    ]} >
      <Grid container spacing={1}>
        <Grid item style={{width: '50%'}}>
          <TextField 
            id="standard-basic" 
            style={{width: '100%'}} 
            label={t('event.name.label')} 
            variant="standard" value={name} 
            margin="normal"
            onChange={handleNameChange} 
          />
        </Grid>
        <Grid item style={{
          width: '50%'
        }}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="yyyy-MM-dd"
            minDate={tomorrow}
            margin="normal"
            id="date-picker-inline"
            label={t('event.date.label')}
            value={date}
            onChange={handleDateChange}
            style={{width: '100%'}}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
      </Grid>
              
    </MuiDialog>
  );
};