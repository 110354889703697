import { createHmac } from 'crypto';
import CommunicationService, { IResponse } from '../../Utils/CommunicationService';
import { LoginData, ILoginResponse} from './types';

export const login = async (data: LoginData): Promise<IResponse<ILoginResponse>> => {
  const result = await CommunicationService.getInstance().publicCall<ILoginResponse>({
    type: 'POST',
    path: '/api/login',
    body: {
      login: data.email,
      password: createHmac('sha256', data.password).digest('hex')
    }
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};