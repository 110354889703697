import { Checkbox, makeStyles, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import { HeadCell, TableOrder } from './TableProvider';

interface TableHeaderProviderProps<T extends {id: number}> {
  headCells: HeadCell<T>[];
  selectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedAll: boolean;
  selectedAny: boolean;
  orderChange?: (order: TableOrder, orderBy: keyof T) => void;
}


const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export function TableHeaderProvider<T extends {id: number}>(props: TableHeaderProviderProps<T> ): JSX.Element {
  const classes = useStyles();
  
  const [order, setOrder] = useState<TableOrder>('desc');
  const [orderBy, setOrderBy] = useState<keyof T>('id');
  
  const {headCells, selectedAll, selectedAny, selectAll, orderChange} = props;

  const createSortHandler = (property: keyof T, headCell: HeadCell<T>) => () => {
    if (headCell.sortable !== false) {
      if (orderBy !== property) {
        setOrder('asc');
        setOrderBy(property);
      } else {
        if (order === 'asc') {
          setOrder('desc');
        } else {
          setOrder('asc');
        }
      }
    }
  };

  useEffect(() => {
    if (orderChange) {
      orderChange(order, orderBy);
    }
  }, [order, orderBy]);

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: HeadCell<T>, index: number) => {
          if (headCell.isCheckbox) {
            return (
              <TableCell padding="checkbox" key={index}>
                <Checkbox
                  indeterminate={selectedAny && !selectedAll}
                  checked={selectedAll}
                  onChange={selectAll}
                  inputProps={{ 'aria-label': 'select all' }}
                />
              </TableCell>
            );
          }
          return (<TableCell
            key={index}
            align={headCell.align ? headCell.align : headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}style={{
              maxWidth: `${headCell.width}px` || '200px',
            }}
          >
            { !!orderChange &&
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id, headCell)}
            >
              {headCell.label}
              {orderBy === headCell.id && headCell.sortable !== false && !!orderChange ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
            }
            {!orderChange && <TableSortLabel hideSortIcon={true}>{headCell.label}</TableSortLabel>}
          </TableCell>
          );})}
      </TableRow>
    </TableHead>
  );
}