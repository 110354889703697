import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MuiDialog } from '../../../Components/Dialog';
import { ImageUpload } from '../../../Components/ImageUpload';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleSave: (data: string) => void;
}

export const AddImpressionImageDialog: React.FC<Props> = ({isOpen, handleClose, handleSave}: Props) => {
  const {t} = useTranslation();
  const [img, setImg] = useState<string | undefined>();

  const _handleClose = () => {
    handleClose();
    setImg(undefined);
  };

  const _handleSave = () => {
    if (img) {
      handleSave(img);
      _handleClose();
    }
  };

  const handleImgChange = (newValue: string) => {
    setImg(newValue);
  };
  
  return (
    <MuiDialog width={500} open={isOpen} onClose={_handleClose} title={t('impressions.add.dialog.title')} buttons={[
      {
        label: t('categories.add.dialog.cancel'),
        action: _handleClose,
      },
      {
        label: t('categories.add.dialog.save'),
        action: _handleSave,
      }
    ]} >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ImageUpload value={img || ''} onValueChange={handleImgChange}/>
        </Grid>
      </Grid>
      
    </MuiDialog>
  );
};