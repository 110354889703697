import React from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { DashboardMenu } from './DashboardMenu/DashboardMenu';
import { Theme, Typography } from '@material-ui/core';

interface ExtendedTheme extends Theme {
  appDrawer: {
    width: string | number
  }
}

const useStyles = makeStyles((theme: ExtendedTheme) => ({
  drawerPaper: {
    background: '#0e627d',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: theme.appDrawer.width,
    borderRight: '#f8ea1d 2px solid',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  logoWrapper: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

interface DashboardDrawerProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

export const DashboardDrawer: React.FC<DashboardDrawerProps> = ({
  isOpen,
  onClose
}: DashboardDrawerProps) => {
  const classes = useStyles();
  const drawerStyle = {
    paper: clsx(classes.drawerPaper, !isOpen && classes.drawerPaperClose)
  };
  return (
    <Drawer variant="permanent" classes={drawerStyle} open={isOpen}>
      <div className={classes.toolbarIcon}>
        <div className={classes.logoWrapper}>
          <Typography component="h2" variant="h4">
          StartKlar
          </Typography>
        </div>
        <IconButton onClick={onClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <DashboardMenu />
    </Drawer>
  );
};
