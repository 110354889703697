import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import '../src/Translations/i18n';
import { SessionProvider } from './Contexts/SessionContext';
import { LocalStorageProvider } from './Services/StorageProvider/LocalStorageProvider';
import { JwtTokenValidator } from './Services/TokenValidator/JwtTokenValidator';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

const store = new LocalStorageProvider();

ReactDOM.render(
  <SessionProvider store={store} tokenValidator={JwtTokenValidator}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <App />
    </MuiPickersUtilsProvider>
  </SessionProvider>,
  document.getElementById('root')
);
