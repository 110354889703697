import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HeadCell, TableProvider } from '../../Components/TableProvider';
import { RowCell } from '../../Components/TableRowProvider';
import { PageLayout } from '../../Themes/PageLayout';
import { deleteEventDate, getEventDates } from './Api';
import { EventDayData, ICategoriesDelResponse, IEventDay } from './types';
import { Grid, IconButton, TableCell } from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { IResponse } from '../../Utils/CommunicationService';
import { ConfirmDialog } from './commons/ConfirmDialog';
import { Separator } from '../../Components/Separator';
import { AddEventDate } from './EventDate/AddEventDate';
import { useHistory } from 'react-router';

export const EventDatePage: React.FC = () => {
  const generateActionCell = (_cellMeta: RowCell<EventDayData>, rowData: EventDayData, _index?: number, key?: string) => {
    return (
      <TableCell align="right" key={key}>                
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <IconButton color="primary" aria-label="info" onClick={editEventBtn(rowData.id)}>
              <ReceiptIcon/>
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <IconButton color="primary" aria-label="info" onClick={deleteEventBtn(rowData.id)}>
              <DeleteIcon/>
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
    );
  };
  const { t } = useTranslation();
  
  const headCells: HeadCell<EventDayData>[] = [
    { id: 'id', align: 'center', numeric: false, width: 40, disablePadding: true, label: t('table.id') },
    { id: 'eventName', numeric: false, disablePadding: false, label: t('table.name') },
    { id: 'eventDate', numeric: true, disablePadding: false, label: t('table.date') },
    { id: 'actions', align: 'left', numeric: true, width: 100, disablePadding: false, label: t('table.actions') },
  ];
  
  const rowsCells: RowCell<EventDayData>[] = [
    { id: 'id', align: 'left', style: {maxWidth: '40px'}, disablePadding: true},
    { id: 'eventName', align: 'left', hasTooltip: true, maxLength: 128},
    { id: 'eventDate', align: 'right', hasTooltip: true, maxLength: 128},
    { id: 'actions', cellGenerator: generateActionCell}
  ];

  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [eventDays, setEventDays] = useState<IEventDay[]>();

  const [confirmDialog, setConfirmDialog] = useState<JSX.Element | undefined>();

  const [eventsCount, setEventCount] = useState<number>();
  const [page, setPage] = useState<number>(0);
  const rowsPerPage = 10;

  const [isAddOpen, setAddOpen] = useState<boolean>(false);

  const refreshData = async () => {
    setLoading(true);
    const data = await getEventDates({page, rowsPerPage: 10});
    if (data.body && data.body.data) {
      setEventDays(data.body.data);
      setEventCount(data.body.dataCount);
    }
    setLoading(false);
  };

  const editEventBtn = (rowId: number) => async () => {
    if (eventDays) {
      const eventById = eventDays.find(el => el.id === rowId);
      if (eventById) {
        history.push(`/backoffice/events/${eventById.id}`);
      }
    }
  };

  const deleteEventBtn = (rowId: number) => async () => {
    setLoading(true);
    const handleDelete = async () => {
      setLoading(true);
      if (eventsCount) {
        const deletedRow: IResponse<ICategoriesDelResponse> = await deleteEventDate(rowId);
        if (deletedRow.body && deletedRow.body.affected) {
          await refreshData();
        }
        setConfirmDialog(undefined);
      }
      setLoading(false);
    };

    const handleDeclineDelete = () => {
      setConfirmDialog(undefined);
    };

    setConfirmDialog(
      <ConfirmDialog isOpen={true} text={t('confirm.deleteEvent.text')} subText={t('confirm.deleteQuestion.subText', {id: rowId})}  onAccept={handleDelete} onCancel={handleDeclineDelete}/>
    );
    
    setLoading(false);
  };

  const addQuestionBtn = () => {
    setAddOpen(true);
  };

  const handleAddClose = () => {
    setAddOpen(false);
  };

  useEffect(() => {
    refreshData();
  }, [page]);

  return (
    <PageLayout lock={isLoading} aligment="left" title={t('evaluation.menuLabel')} buttons={[{icon: AddBoxIcon, action: addQuestionBtn}]}>
      <div>
        {confirmDialog}
        <AddEventDate isOpen={isAddOpen} handleClose={handleAddClose} />

        <Separator/>
        <TableProvider<EventDayData> 
          headCells={headCells}
          rowCells={rowsCells}
          tableData={eventDays || []}
          selectedRows={[]}
          tableDataCount={eventsCount || 0}
          paginated={true}
          paginationRange={[rowsPerPage]}
          rowsPerPage={rowsPerPage}
          pageChange={(newPage) => {
            setPage(newPage);
          }}
          page={page}
          rowsPerPageChange={() => {/** */}}
        />
      </div>
    </PageLayout>
  );
};
