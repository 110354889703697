import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HeadCell, TableProvider } from '../../Components/TableProvider';
import { RowCell } from '../../Components/TableRowProvider';
import { PageLayout } from '../../Themes/PageLayout';
import { addEvalQuestion, deleteEvaluationQuestion, editEvalQuestion, getEvaluationQuestions } from './Api';
import { EvaluationData, ICategoriesDelResponse, IEvalQuestion, IEvalQuestionAddEdit } from './types';
import { FormControlLabel, Grid, IconButton, Switch, TableCell, TextField, Tooltip } from '@material-ui/core';
import { TFunction } from 'i18next';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { green, red } from '@material-ui/core/colors';
import { MuiDialog } from '../../Components/Dialog';
import { IResponse } from '../../Utils/CommunicationService';
import { ConfirmDialog } from './commons/ConfirmDialog';
import { Separator } from '../../Components/Separator';

export const EvaluationPage: React.FC = () => {
  const enabledCellGenerator = (field: string) => (_cellMeta: RowCell<EvaluationData>, rData: EvaluationData, _index?: number, key?: string) => isEnabled(rData[field as keyof EvaluationData], t, key);
  const isEnabled = (isEnabled: boolean, t: TFunction, key?: string): JSX.Element => {
    let icon;
    if (isEnabled) {
      icon = (<CheckCircleIcon style={{ right: 0, color: green[500] }}/>);
    } else {
      icon = (<HighlightOffIcon style={{ right: 0, color: red[500] }}/>);
    }
    return (
      <TableCell align="right" key={key} style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '200px'
      }}>
        <Tooltip title={t(`category.${isEnabled ? 'enabled' : 'disabled'}`) as string}>
          {icon}
        </Tooltip>
      </TableCell>);
  }; 
  
  const generateActionCell = (_cellMeta: RowCell<EvaluationData>, rowData: EvaluationData, _index?: number, key?: string) => {
    return (
      <TableCell align="right" key={key}>                
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <IconButton color="primary" aria-label="info" onClick={editQuestionBtn(rowData.id)}>
              <ReceiptIcon/>
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <IconButton color="primary" aria-label="info" onClick={deleteQuestionyBtn(rowData.id)}>
              <DeleteIcon/>
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
    );
  };
  const { t } = useTranslation();
  
  const headCells: HeadCell<EvaluationData>[] = [
    { id: 'id', align: 'center', numeric: false, width: 40, disablePadding: true, label: t('table.id') },
    { id: 'questionText', numeric: false, disablePadding: false, label: t('table.question') },
    { id: 'isActive', numeric: true, disablePadding: false, label: t('table.active') },
    { id: 'actions', align: 'left', numeric: true, width: 100, disablePadding: false, label: t('table.actions') },
  ];
  
  const rowsCells: RowCell<EvaluationData>[] = [
    { id: 'id', align: 'left', style: {maxWidth: '40px'}, disablePadding: true},
    { id: 'questionText', align: 'left', hasTooltip: true, maxLength: 128},
    { id: 'isActive', align: 'right', cellGenerator: enabledCellGenerator('isActive')},
    { id: 'actions', cellGenerator: generateActionCell}
  ];

  const [isLoading, setLoading] = useState(true);
  const [questions, setQuestions] = useState<IEvalQuestion[]>();

  const [questionText, setQuestionText] = useState<string>();
  const [isActive, setIsActive] = useState<boolean>(false);
  
  const [editingRowId, setEditingRowId] = useState<number | undefined>();
  const [confirmDialog, setConfirmDialog] = useState<JSX.Element | undefined>();

  const [questionsCount, setQuestionsCount] = useState<number>();
  const [page, setPage] = useState<number>(0);
  const rowsPerPage = 10;

  const [isEditOpen, setEditOpen] = useState<boolean>(false);
  const [isAddOpen, setAddOpen] = useState<boolean>(false);

  const refreshData = async () => {
    setLoading(true);
    const cats = await getEvaluationQuestions({page, rowsPerPage: 10});
    if (cats.body && cats.body.data) {
      setQuestions(cats.body.data);
      setQuestionsCount(cats.body.dataCount);
    }
    setLoading(false);
  };

  const editQuestionBtn = (rowId: number) => async () => {
    if (questions) {
      const questionById = questions.find(el => el.id === rowId);
      if (questionById) {
        setEditingRowId(rowId);
        setQuestionText(questionById.questionText);
        setIsActive(questionById.isActive);
        setEditOpen(true);
      }
    }
  };

  const deleteQuestionyBtn = (rowId: number) => async () => {
    setLoading(true);
    const handleDelete = async () => {
      setLoading(true);
      if (questions) {
        const deletedRow: IResponse<ICategoriesDelResponse> = await deleteEvaluationQuestion(rowId);
        if (deletedRow.body && deletedRow.body.affected) {
          await refreshData();
        }
        setConfirmDialog(undefined);
      }
      setLoading(false);
    };

    const handleDeclineDelete = () => {
      setConfirmDialog(undefined);
    };

    setConfirmDialog(
      <ConfirmDialog isOpen={true} text={t('confirm.deleteQuestion.text')} subText={t('confirm.deleteQuestion.subText', {id: rowId})}  onAccept={handleDelete} onCancel={handleDeclineDelete}/>
    );
    
    setLoading(false);
  };

  const addQuestionBtn = () => {
    setAddOpen(true);
  };

  const handleAddClose = () => {
    setIsActive(false);
    setQuestionText('');
    setAddOpen(false);
  };

  const handleEditClose = () => {
    setIsActive(false);
    setQuestionText('');
    setEditOpen(false);
  };

  const handleQuestionChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setQuestionText(ev.target.value);
  };

  const handleActiveChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setIsActive(ev.target.checked);
  };

  const saveNewData = async () => {
    if (questionText) {
      setLoading(true);
      const data: IEvalQuestionAddEdit = {
        questionText: questionText || '',
        isActive
      };
      const result = await addEvalQuestion(data);
      handleAddClose();
      if (result.body) {
        await refreshData();
      }
      setLoading(false);
    }
  };

  const editNewData = async () => {
    if (editingRowId) {
      setLoading(true);
      const data: IEvalQuestionAddEdit = {
        questionText: questionText || '',
        isActive
      };
      const result = await editEvalQuestion(data, editingRowId);
      handleEditClose();
      if (result.body) {
        await refreshData();
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    refreshData();
  }, [page]);

  return (
    <PageLayout lock={isLoading} aligment="left" title={t('evaluation.menuLabel')} buttons={[{icon: AddBoxIcon, action: addQuestionBtn}]}>
      <div>
        {confirmDialog}

        <MuiDialog width={500} open={isAddOpen} onClose={handleAddClose} title={t('evaluation.add.dialog.title')} buttons={[
          {
            label: t('categories.add.dialog.cancel'),
            action: handleAddClose,
          },
          {
            label: t('categories.add.dialog.save'),
            action: saveNewData,
          }
        ]} >
          <Grid container spacing={1}>
            <Grid item style={{width: '80%'}}>
              <TextField id="standard-basic" style={{width: '100%'}} label={t('evaluation.question.label')} variant="standard" value={questionText} onChange={handleQuestionChange} />
            </Grid>
            <Grid item style={{
              justifyContent: 'center',
              display: 'flex',
              width: '20%'
            }}>
              <FormControlLabel
                style={{width: '100%'}}
                control={
                  <Switch checked={isActive} onChange={handleActiveChange} />
                }
                label={t('categories.add.dialog.active.field')}
              />
            </Grid>
          </Grid>
          
        </MuiDialog>

        <MuiDialog width={500} open={isEditOpen} onClose={handleEditClose} title={t('evaluation.edit.dialog.title', {id: editingRowId})} buttons={[
          {
            label: t('categories.edit.dialog.cancel'),
            action: handleEditClose,
          },
          {
            label: t('categories.edit.dialog.edit'),
            action: editNewData,
          }
        ]} >
          <Grid container spacing={1}>
            <Grid item style={{width: '70%'}}>
              <TextField id="standard-basic" style={{width: '100%'}} label={t('evaluation.edit.dialog.name.field')} variant="standard" value={questionText} onChange={handleQuestionChange} />
            </Grid>
            <Grid item xs={4} style={{
              justifyContent: 'center',
              display: 'flex',
              width: '30%'
            }}>
              <FormControlLabel
                style={{width: '100%'}}
                control={
                  <Switch checked={isActive} onChange={handleActiveChange} />
                }
                label={t('categories.edit.dialog.active.field')}
              />
            </Grid>
          </Grid>
          
        </MuiDialog>

        <Separator/>
        <TableProvider<EvaluationData> 
          headCells={headCells}
          rowCells={rowsCells}
          tableData={questions || []}
          selectedRows={[]}
          tableDataCount={questionsCount || 0}
          paginated={true}
          paginationRange={[rowsPerPage]}
          rowsPerPage={rowsPerPage}
          pageChange={(newPage) => {
            setPage(newPage);
          }}
          page={page}
          rowsPerPageChange={() => {/** */}}
        />
      </div>
    </PageLayout>
  );
};
