import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, PropTypes, styled } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export interface DialogButton {
  icon?: React.ElementType;
  label?: string;
  action: () => void;
  color?: PropTypes.Color;
  disabled?: boolean;
}

export interface DialogProps {
  open: boolean;
  children?: React.ReactNode;
  onClose: () => void;
  title: string;
  buttons?: DialogButton[];
  width?: number;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export const MuiDialog: React.FC<DialogProps> = ({width, open, children, onClose, title, buttons}: DialogProps) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <BootstrapDialog
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="mui-dialog-title"
      open={open}
    >
      <DialogTitle >
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          lineHeight: '48px',
          width: width ? `${width}px` : undefined
        }}>
          {title}
          {onClose ? (
            <IconButton
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}

        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div 
          style={{width: width ? `${width}px` : undefined}}
        >
          {children}
        </div>
      </DialogContent>
      {buttons && (
        <DialogActions>
          {
            buttons.map(({icon: Icon, label, action, color, disabled}) => {
              if (Icon && label) {
                return (
                  <Button variant="contained" disabled={disabled} color={color} onClick={action} endIcon={<Icon />}>
                    {label}
                  </Button>
                );
              } else if (!Icon && label) {
                return (
                  <Button variant="contained" disabled={disabled} color={color} onClick={action} >
                    {label}
                  </Button>
                );
              } else if (Icon && !label) {
                <IconButton
                  onClick={action}
                  color={color}
                >
                  <Icon />
                </IconButton>;
              }
              return null;
            })
          }
        </DialogActions>
      )}
    </BootstrapDialog>
  );
};