import { Avatar, Chip, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, Switch, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MuiDialog } from '../../../Components/Dialog';
import { getCategories } from '../Api';
import { IArticleMutationObject, ICategoriesResponse } from '../types';
import { ImageUpload } from '../../../Components/ImageUpload';
import { v4 as uuidv4 } from 'uuid';

import NotInterestedIcon from '@material-ui/icons/NotInterested';
import AddBoxIcon from '@material-ui/icons/AddBox';

interface PropsAdd {
  isEdit?: false | undefined;
  isOpen: boolean;
  handleClose: () => void;
  handleSave: (data?: IArticleMutationObject | undefined) => Promise<void>;
}

interface PropsEdit {
  isEdit: true;
  isOpen: boolean;
  handleClose: () => void;
  handleEdit: (data?: IArticleMutationObject | undefined) => Promise<void>;
  inputData: IArticleMutationObject | undefined;
}

type Props = PropsAdd | PropsEdit;

type SingleError = string[];

export interface ErrorArticleData {
  whole?: SingleError;
  articleText?: SingleError;
  factText?: SingleError;
  hashtag?: SingleError;
  image?: SingleError;
  externalLink?: SingleError;
  isActive?: SingleError;
  categoryId?: SingleError;
}

export const AddEditDialog: React.FC<Props> = ({isOpen, handleClose, isEdit, ...props}: Props) => {
  const {t} = useTranslation();
  const [errors, setErrors] = useState<ErrorArticleData>({});
  const [dialogObject, setDialogObject] = useState<IArticleMutationObject | undefined>();
  const [categories, setCategories] = useState<ICategoriesResponse[]>();
  const [temporaryHashtag, setTemporaryHashtag] = useState<string>('');
  const [temporaryLink, setTemporaryLink] = useState<string>('');

  const gatherData = async () => {
    const cats = await getCategories({page: 0, rowsPerPage: 10});
    if (cats.body && cats.body.data) {
      setCategories(cats.body.data);
    }
  };

  useEffect(() => {
    if (isOpen) {
      gatherData();
    }
  }, [isOpen]);

  useEffect(() => {
    const data = (props as PropsEdit).inputData;
    if (isEdit && isOpen && data) {
      prefillData(data);
    }
  }, [isEdit, isOpen, (props as PropsEdit).inputData]);

  const prefillData = (inputData: IArticleMutationObject) => {
    //clear hashtags
    const copy = JSON.parse(JSON.stringify(inputData));
    copy.hashtag = copy.hashtag.split(' ').map((el: string) => el.replace('#', '')).join(' ');
    handleImageChange(inputData.image, copy);
  };

  const handleCategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const copy: IArticleMutationObject = getCopyOfDialogObject();
    const res = event.target.value as number;
    if (res) {
      copy['categoryId'] = res;
      if (errors.categoryId) {
        const err = getCopyOfErrors();
        err.categoryId = undefined;
        setErrors(err);
      }
    }
    setDialogObject(copy);
  };

  const getCopyOfErrors = (): ErrorArticleData => {
    let copy: ErrorArticleData;
    if (!errors) {
      copy = {};
    } else {
      copy = JSON.parse(JSON.stringify(errors));
    }
    return copy;
  };

  const getCopyOfDialogObject = (): IArticleMutationObject => {
    let copy: IArticleMutationObject;
    if (!dialogObject) {
      copy = {
        articleText: '',
        factText: '',
        hashtag: '',
        image: '',
        externalLink: '',
        isActive: false,
        categoryId: -1
      };
    } else {
      copy = JSON.parse(JSON.stringify(dialogObject));
    }
    return copy;
  };
  
  const addHashtag = () => {
    if (!temporaryHashtag) {
      return;
    }
    const copy: IArticleMutationObject = getCopyOfDialogObject();
    if (!copy['hashtag']) {
      copy['hashtag'] = temporaryHashtag;
    } else {
      copy['hashtag'] += ` ${temporaryHashtag}`;
    }
    setDialogObject(copy);
    setTemporaryHashtag('');
    
  };

  const validURL = (str: string) => {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  };

  const addExternalLink = () => {
    if (!temporaryLink) {
      return;
    }
    const copy: IArticleMutationObject = getCopyOfDialogObject();
    let temp = temporaryLink;
    temp.replace('https://', '');
    temp.replace('http://', '');
    temp = `https://${temp}`;
    if (validURL(temp)) {
      if (!copy['externalLink']) {
        copy['externalLink'] = temp;
      } else {
        copy['externalLink'] += ` ${temp}`;
      }
      setDialogObject(copy);
      setTemporaryLink('');
    } else {
      
      const errors = {hasErrors: true, errors: {}};
      const listOfErrors = getCopyOfErrors();
      listOfErrors.externalLink = addError(listOfErrors.externalLink, t('error.object.externalLink.invalid'));
      errors.errors = listOfErrors;
      propagateErrors(errors);
    }

  };

  const addError = (list: string[] | undefined, error: string) => {
    let res = list;
    if (!res) {
      res = [];
    }
    res.push(error);
    return res;
  };

  const handleAddClose = () => {
    setDialogObject(undefined);
    setErrors({});
    handleClose();
  };

  const propagateErrors = (errors: {hasErrors: boolean, errors?: ErrorArticleData}) => {
    if (errors.errors) {
      setErrors(errors.errors);

    }
  };

  const finishModal = async () => {
    const validateResult = validateArticle();
    if (validateResult.hasErrors) {
      propagateErrors(validateResult);
    } else {
      const cdo = getCopyOfDialogObject();
      cdo.hashtag = cdo.hashtag.split(' ').map((el: string) => `#${el}`).join(' ');
      if (isEdit === false) {
        await (props as PropsAdd).handleSave(cdo);
        setDialogObject(undefined);
        setErrors({});
      } else {
        await (props as PropsEdit).handleEdit(cdo);
        setDialogObject(undefined);
        setErrors({});
      }
    }
  };

  const validateArticle = (): {hasErrors: boolean, errors?: ErrorArticleData} => {
    const errors = {hasErrors: false, errors: {}};
    const listOfErrors: ErrorArticleData = {} as ErrorArticleData;
    if (!dialogObject) {
      addError(listOfErrors.whole, t('error.object.empty'));
    } else {
      if (!dialogObject.articleText) {
        listOfErrors.articleText = addError(listOfErrors.articleText, t('error.object.empty'));
      }
      if (dialogObject.articleText && dialogObject.articleText.length > 1024) {
        listOfErrors.articleText = addError(listOfErrors.articleText, t('error.object.article.too.long', {length: '1024'}));
      }

      if (!dialogObject.factText) {
        listOfErrors.factText = addError(listOfErrors.factText, t('error.object.empty'));
      }
      if (dialogObject.factText && dialogObject.factText.length > 256) {
        listOfErrors.factText = addError(listOfErrors.factText, t('error.object.factText.too.long', {length: '256'}));
      }

      if (!dialogObject.hashtag) {
        listOfErrors.hashtag = addError(listOfErrors.hashtag, t('error.object.empty'));
      }
      if (dialogObject.hashtag && dialogObject.hashtag.split(' ').length > 20) {
        listOfErrors.hashtag = addError(listOfErrors.hashtag, t('error.object.hashtag.too.long', {length: '20'}));
      }

      if (!dialogObject.externalLink) {
        listOfErrors.externalLink = addError(listOfErrors.externalLink, t('error.object.empty'));
      }
      if (dialogObject.externalLink && dialogObject.externalLink.split(' ').length > 5) {
        listOfErrors.externalLink = addError(listOfErrors.externalLink, t('error.object.externalLink.too.long', {length: '5'}));
      }

      if (!dialogObject.categoryId || dialogObject.categoryId < 0) {
        listOfErrors.categoryId = addError(listOfErrors.categoryId, t('error.object.empty'));
      }

      if (!dialogObject.image) {
        listOfErrors.image = addError(listOfErrors.image, t('error.object.empty'));
      }
    }
    errors.errors = listOfErrors;
    if (Object.keys(listOfErrors).length > 0) {
      errors.hasErrors = true;
    }
    return errors;
  };

  const handleImageChange = (base64: string, value?: IArticleMutationObject) => {
    const copy: IArticleMutationObject = value || getCopyOfDialogObject();
    let temp64 = base64;
    if (!temp64.startsWith('data:image/')) {
      temp64 = `data:image/png;base64,${temp64}`;
    }
    copy.image = temp64;
    if (errors.image) {
      const err = getCopyOfErrors();
      err.image = undefined;
      setErrors(err);
    }
    setDialogObject(copy);
  };

  const handleFieldChange = (fieldName: keyof IArticleMutationObject) => (ev: React.ChangeEvent<HTMLInputElement>) => {
    const copy: IArticleMutationObject = getCopyOfDialogObject();

    if (fieldName === 'isActive') {
      copy[fieldName] = !!ev.target.checked;
    } else if (fieldName === 'categoryId') {
      copy[fieldName] = Number(ev.target.value);
    } else if (fieldName !== 'id') {
      copy[fieldName] = ev.target.value;
    }
    if (errors[fieldName as keyof ErrorArticleData]) {
      const err = getCopyOfErrors();
      err[fieldName as keyof ErrorArticleData] = undefined;
      setErrors(err);
    }
    setDialogObject(copy);
  };

  const deleteChipElement = (el: string, field: 'hashtag' | 'externalLink') => () => {
    const copy: IArticleMutationObject = getCopyOfDialogObject();
    const hashtags = copy[field].split(' ');
    const id = hashtags.indexOf(el);
    if (id >= 0) {
      hashtags.splice(id, 1);
    }
    copy[field] = hashtags.join(' ');
    setDialogObject(copy);
  };

  const handleTemporaryHashtag = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (/^([a-zA-Z0-9]+)$/.test(ev.target.value) || ev.target.value === '') {
      setTemporaryHashtag(ev.target.value);
    }
  };

  const handleTemporaryLink = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (/^([a-zA-Z0-9.?&=%;:/-]+)$/.test(ev.target.value) || ev.target.value === '') {
      setTemporaryLink(ev.target.value);
    }
  };

  return (
    <MuiDialog width={500} open={isOpen} onClose={handleAddClose} title={isEdit ? t('knowledge.add.dialog.title.edit', {id: `${dialogObject?.id || ''}`}) : t('knowledge.add.dialog.title.add')} buttons={[
      {
        label: t('knowledge.add.dialog.cancel'),
        action: handleAddClose
      },
      {
        label: isEdit ? t('knowledge.add.dialog.edit') : t('knowledge.add.dialog.save'),
        action: finishModal,
        disabled: !dialogObject
      }
    ]} >
      <Grid container spacing={1}>
        {errors.whole && (
          <Grid item xs={12}>
            <div>
              <Typography variant="subtitle1" component="span" style={{color: '#f44336'}}>
                {errors.whole[0]}
              </Typography>
            </div>
          </Grid>
        )}
        <Grid item xs={8}>
          <FormControl fullWidth>
            <InputLabel id="category-selector-label">{t('categories.menuLabel')}</InputLabel>
            <Select
              labelId="category-selector-label"
              id="category-selector"
              value={dialogObject && dialogObject['categoryId']}
              fullWidth
              onChange={handleCategoryChange}
              error={!!errors.categoryId}
            >
              {categories?.map((category) => {
                return (<MenuItem key={`category_menu_item_${uuidv4()}`} value={category.id}>{category.categoryName}</MenuItem>);
              })}
            </Select>
            {errors.categoryId && 
              <FormHelperText>
                {errors.categoryId.map(el => <div  style={{color: '#f44336'}} key={uuidv4()}>{el}<br/></div>)}
              </FormHelperText>
            }
            {!errors.categoryId &&
              <FormHelperText>{t('knowledge.add.dialog.category.helper')}</FormHelperText>
            }
          </FormControl>
        </Grid>
        <Grid item xs={4} style={{
          justifyContent: 'center',
          display: 'flex'
        }}>
          <FormControlLabel
            style={{
              width: '100%',
              marginRight: '-48px',
              marginLeft: '0px'
            }}
            control={
              <Switch checked={dialogObject && dialogObject['isActive']} onChange={handleFieldChange('isActive')} />
            }
            label={t('knowledge.add.dialog.active.field')}
          />
        </Grid>
        <Grid item xs={12}>
          <ImageUpload value={dialogObject && dialogObject['image'] ? dialogObject['image'] : ''} onValueChange={handleImageChange}/>
          {errors.image && (
            <Grid item xs={12}>
              <div>
                <Typography variant="subtitle1" component="span" style={{color: '#f44336'}}>
                  {errors.image[0]}
                </Typography>
              </div>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField 
            id="standard-basic" 
            style={{width: '100%'}} 
            label={t('knowledge.add.dialog.factText')} 
            variant="standard" 
            value={dialogObject && dialogObject['factText']} 
            onChange={handleFieldChange('factText')} 
            error={!!errors.factText}
            helperText={
              errors.factText && (errors.factText.map(el => <div  style={{color: '#f44336'}} key={uuidv4()}>{el}<br/></div>))
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            style={{width: '100%'}}
            id="standard-multiline-basic"
            label={t('knowledge.add.dialog.article')}
            multiline
            maxRows={8}
            minRows={2}
            onChange={handleFieldChange('articleText')}
            value={dialogObject && dialogObject['articleText']}
            variant={'outlined'}
            error={!!errors.articleText}
            helperText={
              errors.articleText && (errors.articleText.map(el => <div  style={{color: '#f44336'}} key={uuidv4()}>{el}<br/></div>))
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant={'subtitle1'} component={'span'}>
                {t('knowledge.add.dialog.sourceLink')}
              </Typography>
            </Grid>
            {errors.externalLink && 
            
            <Grid item xs={12}>
              <FormHelperText>
                {errors.externalLink.map(el => <div  style={{color: '#f44336'}} key={uuidv4()}>{el}<br/></div>)}
              </FormHelperText>
            </Grid>
            }
            <Grid item xs={12} style={{
              minHeight: '60px',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
              padding: '8px 4px'
            }}>
              {!(dialogObject && dialogObject['externalLink']) && (
                <div>
                  <Grid container spacing={1}>
                    <Grid item xs={12} style={{
                      justifyContent: 'center',
                      display: 'flex',
                    }}>
                      <NotInterestedIcon />
                    </Grid>
                    <Grid item xs={12} style={{
                      justifyContent: 'center',
                      display: 'flex',
                    }}>
                      <Typography variant={'subtitle1'} component={'span'}>
                        {t('knowledge.add.dialog.noSourceLink')}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              )}
              {(dialogObject && dialogObject['externalLink']) && dialogObject['externalLink'].split(' ').map((el: string) => {
                return (
                  <div key={uuidv4()} style={{padding: '2px', display: 'inline-flex'}}>
                    <Chip 
                      color="primary" 
                      onDelete={deleteChipElement(el, 'externalLink')} 
                      avatar={
                        <Avatar
                          style={{
                            width: '45px',
                            borderRadius: '12px',
                            borderTopRightRadius: '4px',
                            borderBottomRightRadius: '4px',
                          }}
                        >https://</Avatar>
                      } 
                      label={<span style={{marginLeft: '-5px'}}>{el.replace('https://', '')}</span>}
                    />
                  </div>);
              })}
            </Grid>
            <Grid item style={{width: '90%'}}>
              
              <Grid container spacing={1} alignItems="flex-end">
                <TextField 
                  id="standard-basic" 
                  style={{width: '100%'}} 
                  label={t('knowledge.add.dialog.sourceLink')} 
                  variant="standard" 
                  value={temporaryLink} 
                  onChange={handleTemporaryLink} 
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span style={{fontSize: '18px', fontWeight: 'bold'}}>https://</span>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid item style={{width: '10%'}}>
              <IconButton disabled={!temporaryLink} color="primary" aria-label="info" onClick={addExternalLink}>
                <AddBoxIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant={'subtitle1'} component={'span'}>
                {t('knowledge.add.dialog.hashtags')}
              </Typography>
            </Grid>
            {errors.hashtag && 
            <Grid item xs={12}>
              <FormHelperText>
                {errors.hashtag.map(el => <div  style={{color: '#f44336'}} key={uuidv4()}>{el}<br/></div>)}
              </FormHelperText>
            </Grid>
            }
            <Grid item xs={12} style={{
              minHeight: '60px',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
              padding: '8px 4px'
            }}>
              {!(dialogObject && dialogObject['hashtag']) && (
                <div>
                  <Grid container spacing={1}>
                    <Grid item xs={12} style={{
                      justifyContent: 'center',
                      display: 'flex',
                    }}>
                      <NotInterestedIcon />
                    </Grid>
                    <Grid item xs={12} style={{
                      justifyContent: 'center',
                      display: 'flex',
                    }}>
                      <Typography variant={'subtitle1'} component={'span'}>
                        {t('knowledge.add.dialog.noHashtag')}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              )}
              {(dialogObject && dialogObject['hashtag']) && dialogObject['hashtag'].split(' ').map((el: string) => {
                return (
                  <div key={`${el}_${uuidv4()}`} style={{padding: '2px', display: 'inline-flex'}}>
                    <Chip color="primary" onDelete={deleteChipElement(el, 'hashtag')} avatar={<Avatar>#</Avatar>} label={el}/>
                  </div>);
              })}
            </Grid>
            <Grid item style={{width: '90%'}} >
              
              <Grid container spacing={1} alignItems="flex-end">
                <TextField 
                  id="standard-basic" 
                  style={{width: '100%'}} 
                  label={t('knowledge.add.dialog.hashtag')} 
                  variant="standard" 
                  value={temporaryHashtag} 
                  onChange={handleTemporaryHashtag} 
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span style={{fontSize: '24px', fontWeight: 'bold'}}>#</span>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid item style={{width: '10%'}} >
              <IconButton disabled={!temporaryHashtag} color="primary" aria-label="info" onClick={addHashtag}>
                <AddBoxIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      
    </MuiDialog>
  );
};