import { Grid, IconButton, TableCell } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HeadCell, TableProvider } from '../../../Components/TableProvider';
import { RowCell } from '../../../Components/TableRowProvider';
import { ISymptomAdd, ISymptomData } from '../types';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import { addSymptom, deleteSymptomAPI, getSymptoms } from '../Api';
import { FirstHelpAddSymptom } from './FirstHelpAddSymptom';

interface Params {
  setLoading: (isLoading: boolean) => void;
}

export const FirstHelpSymptomsPage: React.FC<Params> = ({setLoading}: Params) => {
  const [page, setPage] = useState<number>(0);
  const [symptomsCount, setSymptomsCount] = useState<number>(0);
  const [symptoms, setSymptoms] = useState<ISymptomData[]>([]);
  const [isAddOpen, setAddOpen] = useState<boolean>(false);

  const {t} = useTranslation();

  const generateSymptomCellTool = (_cellMeta: RowCell<ISymptomData>, rowData: ISymptomData, _index?: number, key?: string) => {
    return (
      <TableCell align="right" key={key}>                
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <IconButton 
              color="primary" aria-label="info" onClick={deleteSymptom(rowData.id)}>
              <DeleteIcon/>
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
    );
  };

  const gatherData = async () => {
    setLoading(true);
    const data = await getSymptoms({
      page,
      rowsPerPage: 10
    });
    if (data && data.body) {
      setSymptoms(data.body.data);
      setSymptomsCount(data.body.dataCount);
    }
    setLoading(false);
  };

  useEffect(() => {
    gatherData(); 
  }, [page]);

  const addNewSymptom = () => {
    setAddOpen(true);
  };

  const deleteSymptom = (id: number) => async () => {
    setLoading(true);
    await deleteSymptomAPI(id);
    await gatherData();
    setLoading(false);
  };

  const headSymptomCells: HeadCell<ISymptomData>[] = [
    { id: 'id', align: 'center', numeric: false, width: 40, disablePadding: true, label: t('table.id') },
    { id: 'symptomText', numeric: false, disablePadding: false, label: t('table.symptom') },
    { id: 'id', align: 'left', numeric: true, width: 100, disablePadding: false, label: t('table.actions') },
  ];
  
  const rowsSymptomCells: RowCell<ISymptomData>[] = [
    { id: 'id', align: 'left', style: {maxWidth: '40px'}, disablePadding: true},
    { id: 'symptomText', align: 'left', hasTooltip: true, maxLength: 128},
    { id: 'id', cellGenerator: generateSymptomCellTool}
  ];

  const handleAddClose = () => {
    setAddOpen(false);
  };

  const handleAddAccept = async (data: ISymptomAdd) => {
    handleAddClose();
    await addSymptom(data);
    await gatherData();
  };

  return (
    <Grid container>
      <FirstHelpAddSymptom isOpen={isAddOpen} handleClose={handleAddClose} handleAccept={handleAddAccept} />
      <Grid item xs={12}>
        <IconButton 
          color="primary" aria-label="info" onClick={addNewSymptom}>
          <AddBoxIcon/>
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <TableProvider<ISymptomData> 
          headCells={headSymptomCells}
          rowCells={rowsSymptomCells}
          tableData={symptoms || []}
          selectedRows={[]}
          tableDataCount={symptomsCount || 0}
          paginated={true}
          paginationRange={[10]}
          rowsPerPage={10}
          pageChange={(newPage: number) => {
            setPage(newPage);
          }}
          page={page}
          rowsPerPageChange={() => {/** */}}
        />
      </Grid>
    </Grid>
  );
};

