import CommunicationService, { IPaginableResponse, IResponse } from '../../Utils/CommunicationService';
import { IPaginableData, ICategoriesResponse, ICategoriesAddBody, ICategoriesDelResponse, ICard, IArticleResponse, IArticleMutationObject, IQuizQuestionResponse, IQuizQuestionMutationObject, IEvalQuestion, IEvalQuestionAddEdit, IEventDay, IQuestionPaginableResponse, IEventDateQuestionAdd, IEventDateQuestion, IEventAnswer, IAnswerDetails, IContact, ISymptom, IContactAdd, ISymptomAdd, IEventImpression, IEventImpressionAdd } from './types';

export const getCategories = async (data: IPaginableData): Promise<IResponse<IPaginableResponse<ICategoriesResponse>>> => {

  const result = await CommunicationService.getInstance().secureCall<IPaginableResponse<ICategoriesResponse>>({
    type: 'GET',
    path: '/api/categories',
    body: {...data, onlyActive: false}
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const addCategory = async (data: ICategoriesAddBody): Promise<IResponse<ICategoriesResponse>> => {

  const result = await CommunicationService.getInstance().secureCall<ICategoriesResponse>({
    type: 'PUT',
    path: '/api/categories',
    body: {...data}
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const editCategory = async (data: ICategoriesAddBody, id: number): Promise<IResponse<ICategoriesResponse>> => {

  const result = await CommunicationService.getInstance().secureCall<ICategoriesResponse>({
    type: 'POST',
    path: `/api/categories/${id}`,
    body: {...data}
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const deleteCategory = async (id: number): Promise<IResponse<ICategoriesDelResponse>> => {

  const result = await CommunicationService.getInstance().secureCall<ICategoriesDelResponse>({
    type: 'DELETE',
    path: `/api/categories/${id}`,
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const getCards = async (): Promise<IResponse<ICard[]>> => {
  const result = await CommunicationService.getInstance().secureCall<ICard[]>({
    type: 'GET',
    path: '/api/qrcodes',
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const getArticles = async (data: IPaginableData, category?: number): Promise<IResponse<IPaginableResponse<IArticleResponse>>> => {

  const result = await CommunicationService.getInstance().secureCall<IPaginableResponse<IArticleResponse>>({
    type: 'GET',
    path: '/api/articles/' + (category !== undefined ? category : ''),
    body: {...data, onlyActive: false}
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const addArticle = async (data: IArticleMutationObject): Promise<IResponse<IArticleResponse>> => {

  const result = await CommunicationService.getInstance().secureCall<IArticleResponse>({
    type: 'PUT',
    path: '/api/articles',
    body: {...data}
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const editArticle = async (data: IArticleMutationObject): Promise<IResponse<IArticleResponse>> => {

  const articleId = data.id;
  delete data.id;
  const result = await CommunicationService.getInstance().secureCall<IArticleResponse>({
    type: 'POST',
    path: `/api/articles/${articleId}`,
    body: {...data}
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const deleteArticle = async (id: number): Promise<IResponse<IArticleResponse>> => {
  const result = await CommunicationService.getInstance().secureCall<IArticleResponse>({
    type: 'DELETE',
    path: `/api/articles/${id}`
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const getQuestions = async (data: IPaginableData, category: number): Promise<IResponse<IPaginableResponse<IQuizQuestionResponse>>> => {

  const result = await CommunicationService.getInstance().secureCall<IPaginableResponse<IQuizQuestionResponse>>({
    type: 'GET',
    path: `/api/questions/${category}`,
    body: {...data, onlyActive: false}
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const deleteQuestion = async (id: number): Promise<IResponse<IQuizQuestionResponse>> => {
  const result = await CommunicationService.getInstance().secureCall<IQuizQuestionResponse>({
    type: 'DELETE',
    path: `/api/questions/${id}`
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const editQuestion = async (id: number, data: IQuizQuestionMutationObject): Promise<IResponse<IQuizQuestionResponse>> => {
  const result = await CommunicationService.getInstance().secureCall<IQuizQuestionResponse>({
    type: 'POST',
    path: `/api/questions/${id}`,
    body: {...data}
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const addQuestion = async (data: IQuizQuestionMutationObject): Promise<IResponse<IQuizQuestionResponse>> => {
  const result = await CommunicationService.getInstance().secureCall<IQuizQuestionResponse>({
    type: 'PUT',
    path: '/api/questions',
    body: {...data}
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const addEvalQuestion = async (data: IEvalQuestionAddEdit): Promise<IResponse<IEvalQuestion>> => {
  const result = await CommunicationService.getInstance().secureCall<IEvalQuestion>({
    type: 'PUT',
    path: '/api/evaluation/questions',
    body: {...data}
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const getEvaluationQuestions = async (data: IPaginableData): Promise<IResponse<IPaginableResponse<IEvalQuestion>>> => {

  const result = await CommunicationService.getInstance().secureCall<IPaginableResponse<IEvalQuestion>>({
    type: 'GET',
    path: '/api/evaluation/questions',
    body: {...data, onlyActive: false}
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const deleteEvaluationQuestion = async (id: number): Promise<IResponse<ICategoriesDelResponse>> => {

  const result = await CommunicationService.getInstance().secureCall<ICategoriesDelResponse>({
    type: 'DELETE',
    path: `/api/evaluation/questions/${id}`,
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const editEvalQuestion = async (data: IEvalQuestionAddEdit, id: number): Promise<IResponse<IEvalQuestion>> => {
  const result = await CommunicationService.getInstance().secureCall<IEvalQuestion>({
    type: 'POST',
    path: `/api/evaluation/questions/${id}`,
    body: {...data}
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const getEventDates = async (data: IPaginableData): Promise<IResponse<IPaginableResponse<IEventDay>>> => {

  const result = await CommunicationService.getInstance().secureCall<IPaginableResponse<IEventDay>>({
    type: 'GET',
    path: '/api/event',
    body: {...data}
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const getEventDate = async (id: number): Promise<IResponse<IEventDay>> => {

  const result = await CommunicationService.getInstance().secureCall<IEventDay>({
    type: 'GET',
    path: `/api/event/${id}`
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const deleteEventDate = async (id: number): Promise<IResponse<ICategoriesDelResponse>> => {

  const result = await CommunicationService.getInstance().secureCall<ICategoriesDelResponse>({
    type: 'DELETE',
    path: `/api/event/${id}`,
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const addEventDate = async (eventName: string, eventDate: string): Promise<IResponse<IEventDay>> => {

  const result = await CommunicationService.getInstance().secureCall<IEventDay>({
    type: 'PUT',
    path: '/api/event',
    body: {eventName, eventDate}
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const getEventDateSurveyQuestions = async (evId: number, pgnUnassinged: IPaginableData, pgnAssigned: IPaginableData, isTeacher?: boolean): Promise<IResponse<IQuestionPaginableResponse>> => {

  const result = await CommunicationService.getInstance().secureCall<IQuestionPaginableResponse>({
    type: 'GET',
    path: `/api/survey/questions/${evId}`,
    body: {
      page: pgnUnassinged.page,
      rowsPerPage: pgnUnassinged.rowsPerPage,
      assignedPage: pgnAssigned.page,
      assignedRowsPerPage: pgnAssigned.rowsPerPage,
      isTeacherQuestions: !!isTeacher
    }
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const addEventDateQuestion = async (questionData: IEventDateQuestionAdd, eventId?: number): Promise<IResponse<IEventDateQuestion>> => {

  const result = await CommunicationService.getInstance().secureCall<IEventDateQuestion>({
    type: 'PUT',
    path: `/api/survey/questions/${eventId}`,
    body: {...questionData}
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const assignUnasignQuestion = async (eventId: number, questionId: number, type: 'assign' | 'unassign'): Promise<IResponse<IEventDateQuestion>> => {

  const result = await CommunicationService.getInstance().secureCall<IEventDateQuestion>({
    type: 'POST',
    path: `/api/survey/questions/${questionId}/${type}/${eventId}`
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const deleteEventQuestion = async (id: number): Promise<IResponse<ICategoriesDelResponse>> => {

  const result = await CommunicationService.getInstance().secureCall<ICategoriesDelResponse>({
    type: 'DELETE',
    path: `/api/survey/questions/${id}`,
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const getEventAnswers = async (evId: number, data: IPaginableData, isTeacher?: boolean): Promise<IResponse<IPaginableResponse<IEventAnswer>>> => {

  const result = await CommunicationService.getInstance().secureCall<IPaginableResponse<IEventAnswer>>({
    type: 'GET',
    path: `/api/survey/answers/${evId}`,
    body: {
      page: data.page,
      rowsPerPage: data.rowsPerPage,
      isTeacherQuestions: !!isTeacher
    }
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const getEventAnswersForQuestion = async (evId: number, questionId: number, data: IPaginableData): Promise<IResponse<IPaginableResponse<IAnswerDetails>>> => {

  const result = await CommunicationService.getInstance().secureCall<IPaginableResponse<IAnswerDetails>>({
    type: 'GET',
    path: `/api/survey/answers/${evId}/question/${questionId}`,
    body: {
      page: data.page,
      rowsPerPage: data.rowsPerPage,
    }
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCSV = async (dateFrom: string, dateTo: string): Promise<IResponse<any>> => {

  const result = await CommunicationService.getInstance().secureCall<IPaginableResponse<IAnswerDetails>>({
    type: 'GET',
    path: '/api/survey/answers/download',
    body: {
      startDate: dateFrom,
      endDate: dateTo,
    }
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const getContacts = async (data: IPaginableData): Promise<IResponse<IPaginableResponse<IContact>>> => {

  const result = await CommunicationService.getInstance().secureCall<IPaginableResponse<IContact>>({
    type: 'GET',
    path: '/api/help/contacts',
    body: {
      page: data.page,
      rowsPerPage: data.rowsPerPage,
    }
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const getSymptoms = async (data: IPaginableData): Promise<IResponse<IPaginableResponse<ISymptom>>> => {

  const result = await CommunicationService.getInstance().secureCall<IPaginableResponse<ISymptom>>({
    type: 'GET',
    path: '/api/help/symptoms',
    body: {
      page: data.page,
      rowsPerPage: data.rowsPerPage,
    }
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const deleteContactAPI = async (id: number): Promise<IResponse<IContact>> => {

  const result = await CommunicationService.getInstance().secureCall<IContact>({
    type: 'DELETE',
    path: `/api/help/contacts/${id}`,
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const deleteSymptomAPI = async (id: number): Promise<IResponse<ISymptom>> => {

  const result = await CommunicationService.getInstance().secureCall<ISymptom>({
    type: 'DELETE',
    path: `/api/help/symptoms/${id}`,
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const addContact = async (data: IContactAdd): Promise<IResponse<IContact>> => {

  const result = await CommunicationService.getInstance().secureCall<IContact>({
    type: 'PUT',
    path: '/api/help/contacts',
    body: {...data}
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const addSymptom = async (data: ISymptomAdd): Promise<IResponse<ISymptom>> => {

  const result = await CommunicationService.getInstance().secureCall<ISymptom>({
    type: 'PUT',
    path: '/api/help/symptoms',
    body: {...data}
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};


export const getImages = async (data: IPaginableData): Promise<IResponse<IPaginableResponse<IEventImpression>>> => {

  const result = await CommunicationService.getInstance().secureCall<IPaginableResponse<IEventImpression>>({
    type: 'GET',
    path: '/api/event/impressions',
    body: {
      page: data.page,
      rowsPerPage: data.rowsPerPage,
    }
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const getImage = async (imgId: number): Promise<IResponse<IEventImpression>> => {

  const result = await CommunicationService.getInstance().secureCall<IEventImpression>({
    type: 'GET',
    path: '/api/event/impressions/' + imgId
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const addImage = async (data: IEventImpressionAdd): Promise<IResponse<IEventImpression>> => {

  const result = await CommunicationService.getInstance().secureCall<IEventImpression>({
    type: 'PUT',
    path: '/api/event/impressions',
    body: {...data}
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

export const deleteImage = async (imgId: number): Promise<IResponse<IEventImpression>> => {

  const result = await CommunicationService.getInstance().secureCall<IEventImpression>({
    type: 'DELETE',
    path: '/api/event/impressions/' + imgId
  });

  if (result.error) {
    console.error(result.error);
  }
  return result;
};

